import cookie from 'js-cookie';

export const setCookieItem = (key, value) => {
  cookie.set(key, value);
};

export const getCookieItem = (key) => {
  return cookie.get(key);
};

export const removeCookieItem = (key) => {
  return cookie.remove(key);
};
