import React, {
  Component,
  Fragment,
} from 'react';
import { Field } from 'redux-form';
import RenderField from '../../common/components/Form/RenderField';
import Button from '../../common/components/Button';
import { email } from '../../common/lib/validation';

require('../../../style/index.css');
require('./forgotPassword.css');

// eslint-disable-next-line react/prefer-stateless-function
class ForgotPasswordForm extends Component {
  render() {
    const {
      submitFormFn,
      formSubmissionError,
      submitting,
    } = this.props;
    return (
      <Fragment>
        <form
          className="needs-validation"
          noValidate
        >
          <div className="personal-info-wrap">
            <Field
              name="email"
              type="text"
              component={RenderField}
              autocorrect="off"
              autocapitalize="none"
              label="Email Address"
              validate={email}
              prepend="mail"
              className="has-prepend"
            />
            <div className="button-wrap">
              <Button
                label="Email My Link"
                onClickFn={submitFormFn}
                className={`nyl-button ${submitting ? 'disabled' : ''}`}
              />
              {formSubmissionError &&
                <p className="submit-error">All inputs must be valid in order to submit the form.</p>
              }
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default ForgotPasswordForm;
