/* eslint-disable no-case-declarations */
import _ from 'lodash';
import {
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  FETCH_PROFILE,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  SIGN_OUT_FAILURE,
} from '../constants';

const createParsedAddress = (address) => {
  const addressParts = address.split('|');
  const formattedAddress = {};
  addressParts.forEach((part, i) => {
    if (i === 0) {
      formattedAddress.streetNumber = part;
    } else if (i === 1) {
      formattedAddress.street = part;
    } else if (i === 2) {
      formattedAddress.city = part;
    } else if (i === 3) {
      formattedAddress.state = part;
    } else if (i === 4) {
      formattedAddress.zip = part;
    }
  });

  return formattedAddress;
};

const defaultState = {
  isFetching: false,
  updateProfileFailed: false,
  updateProfileSuccess: false,
  signOutFailure: false,
  errorDetail: false,
  statusCode: false,
  profile: {},
  fetchProfileFailed: false,
};

export default (state = defaultState, action) => {
  const errorDetail = _.get(action.payload, 'errorDetail', false);
  const statusCode = _.get(action.payload, 'statusCode', false);
  switch (action.type) {
    case UPDATE_PROFILE:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        updateProfileSuccess: true,
        updateProfileFailed: false,
        errorDetail: false,
      };
    case UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        isFetching: false,
        updateProfileFailed: true,
        errorDetail,
      };
    case FETCH_PROFILE:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_PROFILE_SUCCESS:
      const {
        profile,
        isUpdateProfile,
      } = action.payload;
      if (isUpdateProfile) {
        const addressOrig = _.get(profile, 'addressLine1', null);
        const addressIso = _.get(profile, 'address_iso', null);
        let addressParts;
        let streetNumber;
        let street;
        let parsedAddressMigrate;
        if (addressIso) {
          addressParts = addressIso.split('|');
          streetNumber = _.get(addressParts, '0', '');
          street = _.get(addressParts, '1', '');
          parsedAddressMigrate = createParsedAddress(addressIso);
          profile.streetNumber = parsedAddressMigrate.streetNumber;
          profile.street = parsedAddressMigrate.street;
        } else if (addressOrig) {
          addressParts = addressOrig.split(' ');
          streetNumber = addressParts.shift();
          street = addressParts.join(' ');
          profile.streetNumber = streetNumber;
          profile.street = street;
        } else {
          profile.streetNumber = '';
          profile.street = '';
          profile.city = '';
          profile.state = '';
          profile.zip = '';
        }
      } else {
        profile.streetNumber = '';
        profile.street = '';
        profile.city = '';
        profile.state = '';
        profile.zip = '';
      }
      return {
        ...state,
        isFetching: false,
        fetchProfileError: true,
        profile,
      };
    case FETCH_PROFILE_FAILURE:
      const errorDetailMessage = _.get(action.payload, 'error.errorDetail', '');
      return {
        ...state,
        isFetching: false,
        fetchProfileFailed: true,
        errorDetail: errorDetailMessage,
      };
    case SIGN_OUT_FAILURE:
      return {
        ...state,
        signOutSuccess: false,
        signOutFailure: true,
        errorDetail,
        statusCode,
      };
    default:
      return { ...state };
  }
};
