/* eslint-disable no-shadow */
/* eslint-disable react/require-default-props */
import React, {
  Component,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import queryString from 'query-string';
import {
  reduxForm,
  formValueSelector,
  getFormSyncErrors,
} from 'redux-form';
import { getUpdateProfileFormValues } from '../../redux/selectors/updateProfileFormSelector';
import { RedirectTimer } from '../../common/components/RedirectTimer';
import {
  fetchProfile,
  updateProfile,
  signOut,
} from '../../redux/actions';
import {
  email,
  phone,
  required,
  noNumbers,
  state,
  zipcode,
  validatePassword,
  cityMaxLength,
  validateDob,
} from '../../common/lib/validation';
import UpdateProfileForm from './UpdateProfileForm';

const formSelector = formValueSelector('updateProfile');

require('../../../style/index.css');
require('./updateProfile.css');

const mapStateToProps = (state) => ({
  updateProfileForm: getUpdateProfileFormValues(state),
  password: formSelector(state, 'password'),
  errorDetail: state.updateProfileReducer.errorDetail,
  updateProfileFailed: state.updateProfileReducer.updateProfileFailed,
  updateProfileSuccess: state.updateProfileReducer.updateProfileSuccess,
  fetchProfileFailed: state.updateProfileReducer.fetchProfileFailed,
  signOutFailure: state.updateProfileReducer.signOutFailure,
  profile: state.updateProfileReducer.profile,
  initialValues: state.updateProfileReducer.profile,
  statusCode: state.updateProfileReducer.statusCode,
  formErrors: getFormSyncErrors('updateProfile')(state),
  isFetching: state.updateProfileReducer.isFetching,
});

const mapDispatchToProps = {
  updateProfile,
  fetchProfile,
  signOut,
};

const isValid = (values) => {
  let valid = true;
  if (required(values.firstName)
    || noNumbers(values.firstName)
    || required(values.lastName)
    || noNumbers(values.lastName)
    || noNumbers(values.middleName)
    || required(values.streetNumber)
    || required(values.street)
    || required(values.city)
    || cityMaxLength(values.city)
    || state(values.state)
    || zipcode(values.zip)
    || phone(values.phone)
    || required(values.birthdate)
    || validateDob(values.birthdate)
    || email(values.email)
    || (values.password && validatePassword(values.password))
    || (values.password && values.password !== values.confirmPassword)) {
    valid = false;
  }
  return valid;
};

// @connect(mapStateToProps, mapDispatchToProps)
class UpdateProfileView extends Component {
  static propTypes = {
    updateProfileForm: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
    ]),
    updateProfile: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.handleUpdateProfileSubmit = this.handleUpdateProfileSubmit.bind(this);
    this.signOutAll = this.signOutAll.bind(this);
    this.state = { formSubmissionError: false };
  }

  componentDidMount() {
    const { fetchProfile } = this.props;
    fetchProfile(true);
  }

  signOutAll(e) {
    const {
      signOut,
      history,
    } = this.props;
    e.preventDefault();
    signOut(history.push);
  }

  handleUpdateProfileSubmit(e) {
    e.preventDefault();
    const {
      updateProfileForm,
      updateProfile,
      touch,
      formErrors,
      history,
    } = this.props;
    if (isValid(updateProfileForm)) {
      updateProfile(updateProfileForm, history.push);
      this.setState({ formSubmissionError: false });
    } else {
      touch(...Object.keys(formErrors));
      this.setState({ formSubmissionError: true });
    }
  }

  render() {
    const {
      password,
      updateProfileSuccess,
      updateProfileFailed,
      fetchProfileFailed,
      signOutFailure,
      errorDetail,
      profile,
      statusCode,
      isFetching,
    } = this.props;
    const { formSubmissionError } = this.state;
    const queryParams = queryString.parse(window.location.search);
    const clientId = _.get(queryParams, 'clientId', false);
    const callbackUri = _.get(queryParams, 'callbackUri', false);
    const accessTokenExpiredErr = errorDetail === 'Access Token has expired' || errorDetail === 'Invalid Access Token';
    return (
      <Fragment>
        <div className="update-profile row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-8 col-sm-10">
            {(
              ((fetchProfileFailed || updateProfileFailed) && accessTokenExpiredErr) ||
                (fetchProfileFailed && !accessTokenExpiredErr)
             ) &&
             <RedirectTimer
               redirectUri={`/login?clientId=${clientId}&callbackUri=/update-profile&secondCallbackUri=${callbackUri}`}
               timer={5000}
               message="Your token has expired. Redirecting you to log in again."
             />
            }
            {!fetchProfileFailed &&
              <UpdateProfileForm
                password={password}
                submitFormFn={this.handleUpdateProfileSubmit}
                updateProfileSuccess={updateProfileSuccess}
                updateProfileFailed={updateProfileFailed}
                signOutFailure={signOutFailure}
                formSubmissionError={formSubmissionError}
                errorDetail={errorDetail}
                profile={profile}
                statusCode={statusCode}
                isFetching={isFetching}
                signOutAllFn={this.signOutAll}
              />
            }
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'updateProfile',
  enableReinitialize: true,
})(UpdateProfileView));

// export default reduxForm({
//   form: 'updateProfile',
//   enableReinitialize : true
// })(UpdateProfileView);
