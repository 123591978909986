import {
  put,
  takeLatest,
  call,
} from 'redux-saga/effects';
import _ from 'lodash';
import queryString from 'query-string';
import { GET_FORGOT_PASSWORD_CODE } from '../constants';
import {
  getForgotPasswordCodeSuccess,
  getForgotPasswordCodeFailure,
} from '../actions';

const createForgotPasswordSagas = (api) => {
  function* forgotPasswordSaga(action) {
    const { forgotPasswordForm } = action.payload;
    try {
      const response = yield call(api.resetPassword, { forgotPasswordForm });
      const statusCode = _.get(response, 'status', false);
      if (statusCode === 200) {
        yield put(getForgotPasswordCodeSuccess());
        const queryParams = queryString.parse(window.location.search);
        const callbackUri = _.get(queryParams, 'callbackUri', false);
        if (callbackUri) {
          window.location.replace(callbackUri);
        }
      } else {
        const errorDetail = _.get(response, 'data.errors.0.message', false);
        yield put(getForgotPasswordCodeFailure({
          statusCode,
          errorDetail,
        }));
      }
    } catch (e) {
      const errorDetail = _.get(e.response, 'data.data.message', false);
      yield put(getForgotPasswordCodeFailure({ errorDetail }));
    }
  }


  function* watchGetForgotPasswordCode() {
    yield takeLatest(GET_FORGOT_PASSWORD_CODE, forgotPasswordSaga);
  }

  return { watchGetForgotPasswordCode };
};

export default createForgotPasswordSagas;
