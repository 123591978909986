import {
  put,
  takeLatest,
  call,
} from 'redux-saga/effects';
import _ from 'lodash';
import queryString from 'query-string';
import { CONFIRM_RESET_PASSWORD } from '../constants';
import {
  confirmResetPasswordSuccess,
  confirmResetPasswordFailure,
} from '../actions';


const createConfirmResetPasswordSagas = (api) => {
  function* confirmResetPasswordSaga(action) {
    const { confirmResetPasswordForm } = action.payload;
    try {
      const queryParams = queryString.parse(window.location.search);
      const clientId = _.get(queryParams, 'clientId', false);
      const secret = _.get(queryParams, 'secret', false);
      const email = _.get(queryParams, 'email', false);
      const code = _.get(queryParams, 'code', false);
      const response = yield call(api.confirmResetPassword, {
        confirmResetPasswordForm,
        clientId,
        secret,
        email,
        code,
      });
      const statusCode = _.get(response, 'status', false);
      if (statusCode === 200) {
        yield put(confirmResetPasswordSuccess());
        const callbackUri = _.get(queryParams, 'callbackUri', false);
        if (callbackUri) {
          window.location.replace(callbackUri);
        }
      } else {
        const errorDetail = _.get(response, 'data.errors[0].detail', false);
        yield put(confirmResetPasswordFailure({
          errorDetail,
          statusCode,
        }));
      }
    } catch (e) {
      const statusCode = _.get(e.response, 'statusCode', false);
      yield put(confirmResetPasswordFailure({
        statusCode,
        e,
      }));
    }
  }


  function* watchConfirmResetPassword() {
    yield takeLatest(CONFIRM_RESET_PASSWORD, confirmResetPasswordSaga);
  }

  return { watchConfirmResetPassword };
};

export default createConfirmResetPasswordSagas;
