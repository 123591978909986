import {
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  FETCH_ADDRESSES,
  FETCH_ADDRESSES_SUCCESS,
  FETCH_ADDRESSES_FAILURE,
  SELECT_ADDRESS,
  SELECT_ADDRESS_SUCCESS,
  SELECT_ADDRESS_FAILURE,
  RESET_AUTOCOMPLETE,
  FETCH_ADDRESSES_MIGRATE,
  FETCH_ADDRESSES_MIGRATE_SUCCESS,
  FETCH_ADDRESSES_MIGRATE_FAILURE,
  SELECT_ADDRESS_MIGRATE,
  SELECT_ADDRESS_MIGRATE_SUCCESS,
  SELECT_ADDRESS_MIGRATE_FAILURE,
  RESET_AUTOCOMPLETE_MIGRATE,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  GET_FORGOT_PASSWORD_CODE,
  GET_FORGOT_PASSWORD_CODE_SUCCESS,
  GET_FORGOT_PASSWORD_CODE_FAILURE,
  CONFIRM_RESET_PASSWORD,
  CONFIRM_RESET_PASSWORD_SUCCESS,
  CONFIRM_RESET_PASSWORD_FAILURE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  FETCH_PROFILE,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  REQUEST_OTP,
  REQUEST_OTP_SUCCESS,
  REQUEST_OTP_FAILURE,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  VERIFY_SSN,
  VERIFY_SSN_SUCCESS,
  VERIFY_SSN_FAILURE,
  MIGRATE_PROFILE,
  MIGRATE_PROFILE_SUCCESS,
  MIGRATE_PROFILE_FAILURE,
  SAVE_USER_PROFILE,
  SELECT_GOV_ID_TYPE,
  FETCH_GOV_ID_RESPONSE,
  FETCH_GOV_ID_RESPONSE_SUCCESS,
  FETCH_GOV_ID_RESPONSE_FAILURE,
  SIGN_OUT,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
} from '../constants';

export const registerUser = (registerUserForm, selectedAddressParsed, push) => ({
  type: REGISTER_USER,
  payload: {
    registerUserForm,
    selectedAddressParsed,
    push,
  },
});

export const registerUserSuccess = ({
  accessToken, refreshToken, identityToken, expiresIn, userDataParsed,
}) => ({
  type: REGISTER_USER_SUCCESS,
  payload: {
    accessToken,
    refreshToken,
    identityToken,
    expiresIn,
    userDataParsed,
  },
});

export const registerUserFailure = (statusCode, errorDetail, duplicatedEmail) => ({
  type: REGISTER_USER_FAILURE,
  payload: {
    statusCode,
    errorDetail,
    duplicatedEmail,
  },
});

export const verifyEmail = () => ({
  type: VERIFY_EMAIL,
  payload: {},
});

export const verifyEmailSuccess = () => ({
  type: VERIFY_EMAIL_SUCCESS,
  payload: {},
});

export const verifyEmailFailure = (statusCode, errorDetail) => {
  return {
    type: VERIFY_EMAIL_FAILURE,
    payload: {
      statusCode,
      errorDetail,
    },
  };
};

export const fetchAddresses = (input) => ({
  type: FETCH_ADDRESSES,
  payload: { input },
});

export const fetchAddressesSuccess = ({ response }) => ({
  type: FETCH_ADDRESSES_SUCCESS,
  payload: { response },
});

export const fetchAddressesFailure = ({ statusCode, errorDetail }) => ({
  type: FETCH_ADDRESSES_FAILURE,
  payload: {
    statusCode,
    errorDetail,
  },
});

export const selectAddress = ({ address }) => ({
  type: SELECT_ADDRESS,
  payload: { address },
});

export const resetAutocomplete = () => ({
  type: RESET_AUTOCOMPLETE,
  payload: {},
});

export const selectAddressSuccess = ({ parsedAddress, address }) => ({
  type: SELECT_ADDRESS_SUCCESS,
  payload: {
    parsedAddress,
    address,
  },
});

export const selectAddressFailure = ({ error }) => ({
  type: SELECT_ADDRESS_FAILURE,
  payload: { error },
});

export const fetchAddressesMigrate = (input) => ({
  type: FETCH_ADDRESSES_MIGRATE,
  payload: { input },
});

export const fetchAddressesMigrateSuccess = ({ response }) => ({
  type: FETCH_ADDRESSES_MIGRATE_SUCCESS,
  payload: { response },
});

export const fetchAddressesMigrateFailure = ({ statusCode, errorDetail }) => ({
  type: FETCH_ADDRESSES_MIGRATE_FAILURE,
  payload: {
    statusCode,
    errorDetail,
  },
});

export const selectAddressMigrate = ({ address }) => ({
  type: SELECT_ADDRESS_MIGRATE,
  payload: { address },
});

export const resetAutocompleteMigrate = () => ({
  type: RESET_AUTOCOMPLETE_MIGRATE,
  payload: {},
});

export const selectAddressMigrateSuccess = ({ parsedAddress, address }) => ({
  type: SELECT_ADDRESS_MIGRATE_SUCCESS,
  payload: {
    parsedAddress,
    address,
  },
});

export const selectAddressMigrateFailure = ({ error }) => ({
  type: SELECT_ADDRESS_MIGRATE_FAILURE,
  payload: { error },
});

export const loginUser = (loginUserForm, push) => ({
  type: LOGIN_USER,
  payload: {
    loginUserForm,
    push,
  },
});

export const loginUserSuccess = ({
  accessToken, refreshToken, identityToken, expiresIn,
}) => ({
  type: LOGIN_USER_SUCCESS,
  payload: {
    accessToken,
    refreshToken,
    identityToken,
    expiresIn,
  },
});

export const loginUserFailure = ({ statusCode, errorDetail }) => ({
  type: LOGIN_USER_FAILURE,
  payload: {
    statusCode,
    errorDetail,
  },
});

export const getForgotPasswordCode = (forgotPasswordForm) => ({
  type: GET_FORGOT_PASSWORD_CODE,
  payload: { forgotPasswordForm },
});

export const getForgotPasswordCodeSuccess = () => ({
  type: GET_FORGOT_PASSWORD_CODE_SUCCESS,
  payload: {},
});

export const getForgotPasswordCodeFailure = ({ statusCode, errorDetail }) => ({
  type: GET_FORGOT_PASSWORD_CODE_FAILURE,
  payload: {
    statusCode,
    errorDetail,
  },
});

export const confirmResetPassword = (confirmResetPasswordForm) => ({
  type: CONFIRM_RESET_PASSWORD,
  payload: { confirmResetPasswordForm },
});

export const confirmResetPasswordSuccess = () => ({
  type: CONFIRM_RESET_PASSWORD_SUCCESS,
  payload: {},
});

export const confirmResetPasswordFailure = ({ statusCode, errorDetail }) => ({
  type: CONFIRM_RESET_PASSWORD_FAILURE,
  payload: {
    statusCode,
    errorDetail,
  },
});

export const updateProfile = (updateProfileForm, push) => ({
  type: UPDATE_PROFILE,
  payload: {
    updateProfileForm,
    push,
  },
});

export const updateProfileSuccess = () => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: {},
});

export const updateProfileFailure = (statusCode, errorDetail) => ({
  type: UPDATE_PROFILE_FAILURE,
  payload: {
    statusCode,
    errorDetail,
  },
});

export const fetchProfile = (isUpdateProfile) => ({
  type: FETCH_PROFILE,
  payload: { isUpdateProfile },
});

export const fetchProfileSuccess = (profile, isUpdateProfile = false) => ({
  type: FETCH_PROFILE_SUCCESS,
  payload: {
    profile,
    isUpdateProfile,
  },
});

export const fetchProfileFailure = (error) => ({
  type: FETCH_PROFILE_FAILURE,
  payload: { error },
});

export const requestOtp = ({ type, showOtpRequestAnimation }) => ({
  type: REQUEST_OTP,
  payload: {
    type,
    showOtpRequestAnimation,
  },
});

export const requestOtpSuccess = ({ asi }) => ({
  type: REQUEST_OTP_SUCCESS,
  payload: { asi },
});

export const requestOtpFailure = (error) => ({
  type: REQUEST_OTP_FAILURE,
  payload: { error },
});

export const verifyOtp = (asi, pin, type, push, onVerifyFailed) => ({
  type: VERIFY_OTP,
  payload: {
    asi,
    pin,
    type,
    push,
    onVerifyFailed,
  },
});

export const verifyOtpSuccess = ({ response }) => ({
  type: VERIFY_OTP_SUCCESS,
  payload: { response },
});

export const verifyOtpFailure = (error) => ({
  type: VERIFY_OTP_FAILURE,
  payload: { error },
});

export const verifySsn = ({ ssn, push }) => ({
  type: VERIFY_SSN,
  payload: {
    ssn,
    push,
  },
});

export const verifySsnSuccess = () => ({
  type: VERIFY_SSN_SUCCESS,
  payload: {},
});

export const verifySsnFailure = (error) => ({
  type: VERIFY_SSN_FAILURE,
  payload: { error },
});

export const migrateProfile = (migrateProfileForm, selectedAddressParsed, push) => ({
  type: MIGRATE_PROFILE,
  payload: {
    migrateProfileForm,
    selectedAddressParsed,
    push,
  },
});

export const migrateProfileSuccess = ({
  accessToken, refreshToken, identityToken, expiresIn, userDataParsed,
}) => ({
  type: MIGRATE_PROFILE_SUCCESS,
  payload: {
    accessToken,
    refreshToken,
    identityToken,
    expiresIn,
    userDataParsed,
  },
});

export const migrateProfileFailure = (statusCode, errorDetail) => ({
  type: MIGRATE_PROFILE_FAILURE,
  payload: {
    statusCode,
    errorDetail,
  },
});

export const saveUserProfile = ({ userData }) => ({
  type: SAVE_USER_PROFILE,
  payload: { userData },
});

export const selectGovIdType = ({ type, flowType }) => ({
  type: SELECT_GOV_ID_TYPE,
  payload: {
    type,
    flowType,
  },
});

export const fetchGovIdResponse = (code, push) => ({
  type: FETCH_GOV_ID_RESPONSE,
  payload: {
    code,
    push,
  },
});

export const fetchGovIdResponseSuccess = ({ response }) => ({
  type: FETCH_GOV_ID_RESPONSE_SUCCESS,
  payload: { response },
});

export const fetchGovIdResponseFailure = (statusCode, errorDetail) => ({
  type: FETCH_GOV_ID_RESPONSE_FAILURE,
  payload: {
    statusCode,
    errorDetail,
  },
});

export const signOut = (push) => ({
  type: SIGN_OUT,
  payload: { push },
});

export const signOutSuccess = () => ({
  type: SIGN_OUT_SUCCESS,
  payload: {},
});

export const signOutFailure = ({ statusCode, errorDetail }) => ({
  type: SIGN_OUT_FAILURE,
  payload: {
    statusCode,
    errorDetail,
  },
});
