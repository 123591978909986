import { createSelector } from 'reselect';
import _ from 'lodash';

const migrateExistingUserFormSelector = (state) => state.form;

const migrateExistingUserSelector = createSelector(
  migrateExistingUserFormSelector,
  (migrateExistingUserForm) => {
    return _.get(migrateExistingUserForm, 'migrateExistingUser.values', false);
  }
);

export const getMigrateExistingUserFormValues = (state) => migrateExistingUserSelector(state);
