/* eslint-disable no-shadow */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/require-default-props */
import React, {
  Component,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  reduxForm,
  formValueSelector,
} from 'redux-form';
import { getForgotPasswordFormValues } from '../../redux/selectors/forgotPasswordSelector';
import { getForgotPasswordCode } from '../../redux/actions';
import { email } from '../../common/lib/validation';
import ForgotPasswordForm from './ForgotPasswordForm';

const formSelector = formValueSelector('forgotPassword');

require('../../../style/index.css');
require('./forgotPassword.css');

const mapStateToProps = (state) => ({
  forgotPasswordForm: getForgotPasswordFormValues(state),
  email: formSelector(state, 'email'),
  getForgotPasswordCodeFailed: state.forgotPasswordReducer.getForgotPasswordCodeFailed,
  getForgotPasswordCodeSuccess: state.forgotPasswordReducer.getForgotPasswordCodeSuccess,
  errorDetail: state.forgotPasswordReducer.errorDetail,
  statusCode: state.forgotPasswordReducer.statusCode,
});

const mapDispatchToProps = { getForgotPasswordCode };

const isValid = (values) => {
  let valid = true;
  if (email(values.email)) {
    valid = false;
  }
  return valid;
};

@connect(mapStateToProps, mapDispatchToProps)
class ForgotPasswordView extends Component {
  static propTypes = {
    forgotPasswordForm: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
    ]),
    getForgotPasswordCode: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.handleGetForgotPasswordCodeSubmit = this.handleGetForgotPasswordCodeSubmit.bind(this);
    this.state = {
      formSubmissionError: false,
      submitting: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (((prevProps.getForgotPasswordCodeFailed !== this.props.getForgotPasswordCodeFailed) ||
    (prevProps.getForgotPasswordCodeSuccess !== this.props.getForgotPasswordCodeSuccess)) &&
    (this.props.getForgotPasswordCodeFailed || this.props.getForgotPasswordCodeSuccess)) {
      this.resetStateValues();
    }
  }

  resetStateValues = () => {
    this.setState({
      formSubmissionError: false,
      submitting: false,
    });
  }

  handleGetForgotPasswordCodeSubmit(e) {
    e.preventDefault();
    if (this.state.submitting) return;
    this.setState({ submitting: true });

    const {
      forgotPasswordForm,
      getForgotPasswordCode,
    } = this.props;
    if (isValid(forgotPasswordForm)) {
      getForgotPasswordCode(forgotPasswordForm);
    } else {
      this.setState({
        formSubmissionError: true,
        submitting: false,
      });
    }
  }

  render() {
    const {
      getForgotPasswordCodeFailed,
      getForgotPasswordCodeSuccess,
      errorDetail,
      statusCode,
      email,
    } = this.props;
    const { formSubmissionError } = this.state;
    return (
      <Fragment>
        <div className="forgot-password row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-8 col-sm-10">
            <ForgotPasswordForm
              submitFormFn={this.handleGetForgotPasswordCodeSubmit}
              getForgotPasswordCodeSuccess={getForgotPasswordCodeSuccess}
              getForgotPasswordCodeFailed={getForgotPasswordCodeFailed}
              formSubmissionError={formSubmissionError}
              errorDetail={errorDetail}
              statusCode={statusCode}
              submitting={this.state.submitting}
            />
            {(getForgotPasswordCodeSuccess || getForgotPasswordCodeFailed) &&
              <div className="alert alert-success mt-5">
                <h4 className="alert-heading text-center text-uppercase">Success!</h4>
                <p className="mt-2 text-center">Thank you. If you're a registered user, we sent you a password reset email to {email}. If you don't receive the email soon, please check your junk and spam folders. Follow the link in the email to reset your password.</p>
              </div>
            }

          </div>
        </div>
      </Fragment>
    );
  }
}

export default reduxForm({ form: 'forgotPassword' })(ForgotPasswordView);
