import {
  put,
  takeLatest,
  call,
} from 'redux-saga/effects';
import _ from 'lodash';
import queryString from 'query-string';
import { VERIFY_EMAIL } from '../constants';
import {
  verifyEmailSuccess,
  verifyEmailFailure,
} from '../actions';


const createVerifyEmailSagas = (api) => {
  function* confirmVerifyEmailSaga() {
    try {
      const queryParams = queryString.parse(window.location.search);
      const clientId = _.get(queryParams, 'clientId', false);
      const secret = _.get(queryParams, 'secret', false);
      const email = _.get(queryParams, 'email', false);
      const code = _.get(queryParams, 'code', false);
      const response = yield call(api.verifyEmail, {
        clientId: clientId && decodeURIComponent(clientId),
        secret: secret && decodeURIComponent(secret),
        email: email && decodeURIComponent(email),
        code: code && decodeURIComponent(code),
      });
      const statusCode = _.get(response, 'status', false);
      if (statusCode === 200) {
        yield put(verifyEmailSuccess());
        const callbackUri = _.get(queryParams, 'callbackUri', false);
        if (callbackUri) {
          window.location.replace(callbackUri);
        }
      } else {
        const errorDetail = _.get(response, 'data.errors[0].detail', false);
        yield put(verifyEmailFailure(
          statusCode,
          errorDetail,
        ));
      }
    } catch (error) {
      const statusCode = _.get(error.response, 'statusCode', false);
      yield put(verifyEmailFailure(
        statusCode,
        error,
      ));
    }
  }


  function* watchVerifyEmail() {
    yield takeLatest(VERIFY_EMAIL, confirmVerifyEmailSaga);
  }

  return { watchVerifyEmail };
};

export default createVerifyEmailSagas;
