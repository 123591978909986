/* eslint-disable no-shadow */
/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-useless-escape */
import moment from 'moment';
import { CITY_MAX_LENGTH } from './constants';

export const isEmpty = (value) => value === undefined || value === null || value === '' || !/\S/.test(value);

function join(rules) {
  return (value, data) => rules.map((rule) => rule(value, data)).filter((error) => !!error)[0];
}

export function normalizeNums(value) {
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!value) {
    return value;
  }
  return onlyNums;
}

export function normalizeSsn4(value) {
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!value) {
    return value;
  } else if (onlyNums.length > 4) {
    return onlyNums.substring(0, 4);
  }
  return onlyNums;
}

export function noNumsNoPeriods(value) {
  const noNumsNoPeriodsStr = value.replace(/[\d\.]/g, '');
  if (!value) {
    return value;
  }
  return noNumsNoPeriodsStr;
}

export function noPeriods(value) {
  const noPeriodsStr = value.replace('.', '');
  if (!value) {
    return value;
  }
  return noPeriodsStr;
}

export function noSpecialChars(value) {
  // const specialChars = '!@#$%^&*()- : ; ” ‘ ! ?'  !@#$%^&*()-:;”‘!?  replace(/[`~!@#$%^&*()?,.<>;':"/\\[\]|{}()=_+-]/g, '')
  const noSpecialCharsStr = value.replace(/[!@#$%^&*()':";?-]/g, '');
  if (!value) {
    return value;
  }
  return noSpecialCharsStr;
}

export function normalizeName(value) {
  // Remove any characters that are not word characters, single quotes, dash, en-dash, or diacritics.
  // Also specifically remove all digits, and commonly-entered specialty characters.
  const nameNormalizer = value.replace(/[^\w\'\-– \u00C0-\u017F]|[\d_!@#$%^&*():";?]/g, '');
  if (!value) {
    return value;
  }
  return nameNormalizer;
}

export function normalizePhone(value, previousValue) {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return `(${onlyNums})`;
    }
    if (onlyNums.length === 6) {
      return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}-`;
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
  }
  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
}

export function normalizeZip(value) {
  if (!value) {
    return value;
  }
  return value.replace(/[^\d]/g, '');
}

export function normalizeDob(value, previousValue) {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 2) {
      return `${onlyNums}/`;
    }
    if (onlyNums.length === 4) {
      return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2)}/`;
    }
  }
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2)}`;
  }
  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(4, 8)}`;
}

export function validateDob(value) {
  if (!isEmpty(value) && !/^(?:0[1-9]|1[0-2])([\/.-])(?:0[1-9]|[12]\d|3[01])\1(?:19|20)\d\d$/.test(value)) {
    return 'Please enter a valid birth date';
  } else if (!isEmpty(value) && /^(?:0[1-9]|1[0-2])([\/.-])(?:0[1-9]|[12]\d|3[01])\1(?:19|20)\d\d$/.test(value)) {
    const age = moment().diff(value, 'years');
    return (age >= 18) ? null : 'You must be 18 years or older to register';
  } else if (isEmpty(value)) {
    return 'Required';
  }
  return null;
}

export function email(value) {
  if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'Invalid email address';
  } else if (isEmpty(value)) {
    return 'Required';
  }

  return null;
}

export function phone(value) {
  if (!isEmpty(value)) {
    let phoneStripped = value.replace('(', '');
    phoneStripped = phoneStripped.replace(')', '');
    phoneStripped = phoneStripped.replace(' ', '');
    phoneStripped = phoneStripped.replace('-', '');
    if (!/^\(?([2-9][0-8][0-9])\)?[-.●]?([2-9][0-9]{2})[-.●]?([0-9]{4})$/g.test(phoneStripped)) {
      return 'Invalid phone number';
    }
  } else if (isEmpty(value)) {
    return 'Required';
  }
  return null;
}

export function zipcode(value) {
  if (!isEmpty(value) && !/^[0-9]{5}(?:-[0-9]{4})?$/.test(value)) {
    return 'Invalid zipcode';
  } else if (isEmpty(value)) {
    return 'Required';
  }
}

export function ssn4(value) {
  if (!isEmpty(value) && !/^[0-9]{4}(?:-[0-9]{4})?$/.test(value)) {
    return 'Invalid SSN4';
  } else if (isEmpty(value)) {
    return 'Required';
  }
}

export function state(value) {
  if (!isEmpty(value) && value === '0') {
    return 'Required';
  } else if (isEmpty(value)) {
    return 'Required';
  }
  return null;
}

export function validatePassword(value) {
  if (!isEmpty(value) && !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(value) || (!isEmpty(value) && value.length > 20)) {
    return 'Your password must follow the password guidelines.';
  } else if (isEmpty(value)) {
    return 'Required';
  }
  return null;
}

export function optionalValidatePassword(value) {
  if (isEmpty(value)) {
    return null;
  } else if (!isEmpty(value) && !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(value) || (!isEmpty(value) && value.length > 20)) {
    return 'Your password must follow the password guidelines.';
  }
  return null;
}

export function required(value) {
  if (isEmpty(value)) {
    return 'Required';
  }

  return null;
}

export const addressInputRequired = (autocompValue) => () => {
  if (isEmpty(autocompValue)) {
    return 'You must select an address from the autocomplete dropdown.';
  }

  return null;
};


export function requiredCheckbox(value) {
  if (isEmpty(value) || !value) {
    return 'Required';
  }
  return null;
}
export function minLength(min) {
  return (value) => {
    if (!isEmpty(value) && value.length < min) {
      return `Must be at least ${min} characters`;
    }

    return null;
  };
}

export function maxLength({ max, value }) {
  if (!isEmpty(value) && value.length > max) {
    return `If your town name is over ${max + 1} characters, please submit only ${max} characters and the form will identify town based on zip code.`;
  }

  return null;
}

export function cityMaxLength(value) {
  return maxLength({
    max: CITY_MAX_LENGTH,
    value,
  });
}

export function fixedLengthSix(value) {
  if (!isEmpty(value) && value.length !== 6) {
    return 'Pin must be 6 characters';
  }
  return null;
}

export function normalizeOtp(value) {
  const onlyNums = value.replace(/[^\d]/g, '');
  const onlyNumsLimited = onlyNums.substr(0, 6);
  if (!value) {
    return value;
  }
  return onlyNumsLimited;
}

export function integer(value) {
  if (!Number.isInteger(Number(value))) {
    return 'Must be an integer';
  }

  return null;
}

export function oneOf(enumeration) {
  return (value) => {
    if (!enumeration.indexOf(value)) {
      return `Must be one of: ${enumeration.join(', ')}`;
    }

    return null;
  };
}

export function match(field) {
  return (value, data) => {
    if (data) {
      if (value !== data[field]) {
        return 'Do not match';
      }
    }

    return null;
  };
}

export function createValidator(rules) {
  return (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      // concat enables both functions and arrays of functions
      const rule = join([].concat(rules[key]));
      const error = rule(data[key], data);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };
}

export function noNumbers(value) {
  if (/[\d]/g.test(value)) {
    return 'Must contain only letters';
  }

  return null;
}

export function getAlphabeticOrder(values) {
  return values.sort();
}

export function noSsn4IsChecked(value, props, entity, state) {
  const { noSsn4 } = props;
  const { ssn4Value } = state;
  entity.setState({ noSsn4Value: value }, () => {
    if (!noSsn4 || !ssn4Value) {
      return 'Required';
    }
    return null;
  });
}

export function ssn4IsEntered(value, props, entity, state) {
  const {
    ssn4,
    noSsn4,
  } = props;
  const { noSsn4Value } = state;
  entity.setState({ ssn4Value: value }, () => {
    if (ssn4 && !noSsn4) {
      return null;
    } else if (noSsn4 && !ssn4) {
      return null;
    } else if (noSsn4Value && !ssn4) {
      return null;
    }
    return 'Required';
  });
}
