import React, { PureComponent } from 'react';
import './index.css';

class Button extends PureComponent {
  render() {
    const {
      onClickFn,
      label,
      disabled,
      className,
      ...props
    } = this.props;
    return (
      <button
        className={className}
        onClick={onClickFn}
        disabled={disabled}
        {...props}
      >
        <span>
          {label}
        </span>
      </button>
    );
  }
}

export default Button;
