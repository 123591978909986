export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';

export const FETCH_ADDRESSES = 'FETCH_ADDRESSES';
export const FETCH_ADDRESSES_SUCCESS = 'FETCH_ADDRESSES_SUCCESS';
export const FETCH_ADDRESSES_FAILURE = 'FETCH_ADDRESSES_FAILURE';

export const SELECT_ADDRESS = 'SELECT_ADDRESS';
export const SELECT_ADDRESS_SUCCESS = 'SELECT_ADDRESS_SUCCESS';
export const SELECT_ADDRESS_FAILURE = 'SELECT_ADDRESS_FAILURE';
export const RESET_AUTOCOMPLETE = 'RESET_AUTOCOMPLETE';

export const FETCH_ADDRESSES_MIGRATE = 'FETCH_ADDRESSES_MIGRATE';
export const FETCH_ADDRESSES_MIGRATE_SUCCESS = 'FETCH_ADDRESSES_MIGRATE_SUCCESS';
export const FETCH_ADDRESSES_MIGRATE_FAILURE = 'FETCH_ADDRESSES_MIGRATE_FAILURE';

export const SELECT_ADDRESS_MIGRATE = 'SELECT_ADDRESS_MIGRATE';
export const SELECT_ADDRESS_MIGRATE_SUCCESS = 'SELECT_ADDRESS_MIGRATE_SUCCESS';
export const SELECT_ADDRESS_MIGRATE_FAILURE = 'SELECT_ADDRESS_MIGRATE_FAILURE';
export const RESET_AUTOCOMPLETE_MIGRATE = 'RESET_AUTOCOMPLETE_MIGRATE';


export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const GET_FORGOT_PASSWORD_CODE = 'GET_FORGOT_PASSWORD_CODE';
export const GET_FORGOT_PASSWORD_CODE_SUCCESS = 'GET_FORGOT_PASSWORD_CODE_SUCCESS';
export const GET_FORGOT_PASSWORD_CODE_FAILURE = 'GET_FORGOT_PASSWORD_CODE_FAILURE';

export const CONFIRM_RESET_PASSWORD = 'CONFIRM_RESET_PASSWORD';
export const CONFIRM_RESET_PASSWORD_SUCCESS = 'CONFIRM_RESET_PASSWORD_SUCCESS';
export const CONFIRM_RESET_PASSWORD_FAILURE = 'CONFIRM_RESET_PASSWORD_FAILURE';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE';

export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';

export const NYL_SSO_ACCESS_TOKEN = 'NYL_SSO_ACCESS_TOKEN';
export const NYL_SSO_REFRESH_TOKEN = 'NYL_SSO_REFRESH_TOKEN';
export const NYL_SSO_IDENTITY_TOKEN = 'NYL_SSO_IDENTITY_TOKEN';
export const NYL_SSO_IDENTITY = 'NYL_SSO_IDENTITY';
export const NYL_SSO_EXPIRES_IN = 'NYL_SSO_EXPIRES_IN';
export const NYL_SSO_FIRST_NAME = 'NYL_SSO_FIRST_NAME';
export const NYL_SSO_LAST_NAME = 'NYL_SSO_LAST_NAME';

export const REQUEST_OTP = 'REQUEST_OTP';
export const REQUEST_OTP_SUCCESS = 'REQUEST_OTP_SUCCESS';
export const REQUEST_OTP_FAILURE = 'REQUEST_OTP_FAILURE';
export const REQUEST_OTP_COOLDOWN_EXPIRE = 'REQUEST_OTP_COOLDOWN_EXPIRE';
export const REQUEST_OTP_HIDE_ANIMATION = 'REQUEST_OTP_HIDE_ANIMATION';

export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';

export const VERIFY_SSN = 'VERIFY_SSN';
export const VERIFY_SSN_SUCCESS = 'VERIFY_SSN_SUCCESS';
export const VERIFY_SSN_FAILURE = 'VERIFY_SSN_FAILURE';

export const MIGRATE_PROFILE = 'MIGRATE_PROFILE';
export const MIGRATE_PROFILE_SUCCESS = 'MIGRATE_PROFILE_SUCCESS';
export const MIGRATE_PROFILE_FAILURE = 'MIGRATE_PROFILE_FAILURE';

export const SAVE_USER_PROFILE = 'SAVE_USER_PROFILE';

export const SELECT_GOV_ID_TYPE = 'SELECT_GOV_ID_TYPE';
export const FETCH_GOV_ID_RESPONSE = 'FETCH_GOV_ID_RESPONSE';
export const FETCH_GOV_ID_RESPONSE_SUCCESS = 'FETCH_GOV_ID_RESPONSE_SUCCESS';
export const FETCH_GOV_ID_RESPONSE_FAILURE = 'FETCH_GOV_ID_RESPONSE_FAILURE';

export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE';
