/* eslint-disable no-case-declarations */
import _ from 'lodash';
import {
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
} from '../constants';

const defaultState = {
  isVerifying: false,
  isEmailVerified: true,
  emailVerificationFailed: false,
  emailVerificationSuccess: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case VERIFY_EMAIL:
      return {
        ...state,
        isVerifying: true,
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        isEmailVerified: true,
        isVerifying: false,
        emailVerificationSuccess: true,
        emailVerificationFailed: false,
        errorDetail: false,
      };
    case VERIFY_EMAIL_FAILURE:
      const errorDetail = _.get(action.payload, 'errorDetail', false);
      const statusCode = _.get(action.payload, 'statusCode', false);
      return {
        ...state,
        isVerifying: false,
        emailVerificationFailed: true,
        errorDetail: errorDetail.message,
        statusCode,
      };
    default:
      return { ...state };
  }
};
