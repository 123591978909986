/* eslint-disable no-case-declarations */
import {
  CONFIRM_RESET_PASSWORD,
  CONFIRM_RESET_PASSWORD_SUCCESS,
  CONFIRM_RESET_PASSWORD_FAILURE,
} from '../constants';

const defaultState = {
  isFetching: false,
  confirmResetPasswordFailed: false,
  confirmResetPasswordSuccess: false,
  errorDetail: false,
  statusCode: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONFIRM_RESET_PASSWORD:
      return {
        ...state,
        isFetching: true,
      };
    case CONFIRM_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        confirmResetPasswordSuccess: true,
        confirmResetPasswordFailed: false,
      };
    case CONFIRM_RESET_PASSWORD_FAILURE:
      const {
        errorDetail,
        statusCode,
      } = action.payload;
      return {
        ...state,
        isFetching: false,
        confirmResetPasswordFailed: true,
        errorDetail,
        statusCode,
      };
    default:
      return { ...state };
  }
};
