import {
  put,
  takeLatest,
  call,
} from 'redux-saga/effects';
import _ from 'lodash';
import queryString from 'query-string';
import {
  saveUserCredentials,
  saveUserName,
} from './../../services/auth';
import { LOGIN_USER } from '../constants';
import {
  loginUserSuccess,
  loginUserFailure,
  saveUserProfile,
} from '../actions';
import { gtagEvent } from '../../common/lib/analyticsEventManager';

const createLoginSagas = (api) => {
  function* loginUserSaga(action) {
    const {
      loginUserForm,
      push,
    } = action.payload;
    try {
      const response = yield call(api.loginUser, { loginUserForm });
      const statusCode = _.get(response, 'data.data.statusCode', false);
      if (statusCode && statusCode === 200) {
        const accessTokenLogin = _.get(response, 'data.data.AccessToken', null);
        const refreshTokenLogin = _.get(response, 'data.data.RefreshToken', null);
        const identityTokenLogin = _.get(response, 'data.data.IdToken', null);
        const expiresIn = _.get(response, 'data.data.ExpiresIn', null);
        const queryParams = queryString.parse(window.location.search);
        const callbackUri = _.get(queryParams, 'callbackUri', false);
        const secondCallbackUri = _.get(queryParams, 'secondCallbackUri', false);
        const clientId = _.get(queryParams, 'clientId', false);
        const callbackUriStr = callbackUri ? `?callbackUri=${callbackUri}&clientId=${clientId}` : '';

        saveUserCredentials({
          accessToken: accessTokenLogin,
          refreshToken: refreshTokenLogin,
          identityToken: identityTokenLogin,
          expiresIn,
        });

        const userData = yield call(api.fetchProfile);
        const userDataParsed = _.get(userData, 'data.data.attributes', false);

        const { firstName, lastName } = userDataParsed;
        saveUserCredentials({
          accessToken: accessTokenLogin,
          refreshToken: refreshTokenLogin,
          identityToken: identityTokenLogin,
          expiresIn,
        });
        saveUserName({
          firstName,
          lastName,
        });

        yield put(saveUserProfile({ userData: userDataParsed }));
        if (!userDataParsed['custom:verified'] || userDataParsed['custom:verified'] === 'N') {
          gtagEvent('DC-5139115/colle0/colle008+standard');
          push(`/idv/migrate${callbackUri ? callbackUriStr : ''}`);
          yield put(loginUserSuccess({
            accessToken: accessTokenLogin,
            refreshToken: refreshTokenLogin,
            identityToken: identityTokenLogin,
            expiresIn,
          }));
        } else if (userDataParsed['custom:verified'] === 'Y' || (userDataParsed['custom:phone_verification'] === 'Y' && userDataParsed['custom:ssn_verification'] === 'Y')) {
          if (callbackUri && secondCallbackUri) {
            gtagEvent('DC-5139115/colle0/colle008+standard');
            window.location.replace(`${callbackUri}?callbackUri=${secondCallbackUri}&clientId=${clientId}&accessToken=${accessTokenLogin}`);
          } else if (callbackUri && !secondCallbackUri) {
            window.location.replace(`${callbackUri}?clientId=${clientId}&accessToken=${accessTokenLogin}&refreshToken=${refreshTokenLogin}&identityToken=${identityTokenLogin}&expiresIn=${expiresIn}`);
          }
        } else if (userDataParsed['custom:verified'] === 'N' && userDataParsed['custom:phone_verification'] === 'Y' && userDataParsed['custom:ssn_verification'] === 'N') {
          gtagEvent('DC-5139115/colle0/colle008+standard');
          push(`/idv/ssn4${callbackUri ? callbackUriStr : ''}`);
          yield put(loginUserSuccess({
            accessToken: accessTokenLogin,
            refreshToken: refreshTokenLogin,
            identityToken: identityTokenLogin,
            expiresIn,
          }));
        }
        // Phase 2 - SMS Validation Flow
        // yield put(loginUserSuccess({ accessTokenLogin, refreshTokenLogin, identityTokenLogin }));
      } else {
        const errorDetail = _.get(response, 'data.errors[0].detail', false);
        yield put(loginUserFailure({
          errorDetail,
          statusCode,
        }));
      }
    } catch (e) {
      const statusCode = _.get(e.response, 'statusCode', false);
      yield put(loginUserFailure({
        statusCode,
        e,
      }));
    }
  }


  function* watchLoginUser() {
    yield takeLatest(LOGIN_USER, loginUserSaga);
  }

  return { watchLoginUser };
};

export default createLoginSagas;
