import { spawn } from 'redux-saga/effects';
import createRegisterSagas from './registerSaga';
import createLoginSagas from './loginSaga';
import createForgotPasswordSagas from './forgotPasswordSaga';
import createConfirmResetPasswordSagas from './confirmResetPasswordSaga';
import createUpdateProfileSagas from './updateProfileSaga';
import createVerifyEmailSagas from './verifyEmailSaga';
import createIdvSagas from './idvSaga';
import createMigrateProfileSagas from './migrateProfileSaga';
import apiFactory from '../../services/api';

export default function* sagas(history) {
  const api = apiFactory.create();
  const {
    watchRegisterUser,
    watchFetchAddresses,
    watchSelectAddress,
  } = createRegisterSagas(api, history);
  const { watchLoginUser } = createLoginSagas(api, history);
  const { watchVerifyEmail } = createVerifyEmailSagas(api, history);

  const { watchGetForgotPasswordCode } = createForgotPasswordSagas(api, history);

  const { watchConfirmResetPassword } = createConfirmResetPasswordSagas(api, history);

  const {
    watchUpdateProfile,
    watchSignOut,
  } = createUpdateProfileSagas(api, history);

  const {
    watchVerifyOtp,
    watchRequestOtp,
    watchVerifySsn,
    watchFetchProfile,
    watchFetchGovIdResponse,
  } = createIdvSagas(api, history);

  const {
    watchMigrateProfile,
    watchSelectAddressMigrate,
    watchFetchAddressesMigrate,
    watchFetchProfileSuccessMigrate,
  } = createMigrateProfileSagas(api, history);

  yield spawn(watchRegisterUser);
  yield spawn(watchFetchAddresses);
  yield spawn(watchSelectAddress);
  yield spawn(watchLoginUser);
  yield spawn(watchVerifyEmail);
  yield spawn(watchGetForgotPasswordCode);
  yield spawn(watchConfirmResetPassword);
  yield spawn(watchUpdateProfile);
  yield spawn(watchSignOut);
  yield spawn(watchFetchProfile);
  yield spawn(watchFetchGovIdResponse);
  yield spawn(watchVerifyOtp);
  yield spawn(watchRequestOtp);
  yield spawn(watchVerifySsn);
  yield spawn(watchMigrateProfile);
  yield spawn(watchSelectAddressMigrate);
  yield spawn(watchFetchAddressesMigrate);
  yield spawn(watchFetchProfileSuccessMigrate);
}
