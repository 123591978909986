/* eslint-disable max-len */
import React, {
  Component,
  Fragment,
} from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import { Field } from 'redux-form';
import RenderField from '../../common/components/Form/RenderField';
import Button from '../../common/components/Button';
import {
  email,
  phone,
  normalizePhone,
  required,
  validateDob,
  normalizeDob,
  state,
  ssn4,
  normalizeZip,
  normalizeSsn4,
  zipcode,
  noPeriods,
  noSpecialChars,
  getAlphabeticOrder,
  cityMaxLength,
  ssn4IsEntered,
  noSsn4IsChecked,
} from '../../common/lib/validation';

import { getError } from '../../common/lib/getError';
import {
  states,
  suffixes,
} from '../../common/lib/generateStates';
import { CITY_HELP_TEXT } from '../../common/lib/constants';

require('../../../style/index.css');
require('./migrateExistingUser.css');

class MigrateExistingUserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ssn4Value: '',
      noSsn4Value: false,
    };
    this.resetAutocompleteFn = this.resetAutocompleteFn.bind(this);
    this.confirmNoSsn4IsChecked = this.confirmNoSsn4IsChecked.bind(this);
    this.confirmSsn4IsEntered = this.confirmSsn4IsEntered.bind(this);
  }

  confirmNoSsn4IsChecked(value) {
    noSsn4IsChecked(value, this.props, this, this.state);
  }

  confirmSsn4IsEntered(value) {
    ssn4IsEntered(value, this.props, this, this.state);
  }

  resetAutocompleteFn() {
    const { resetAutocomplete } = this.props;
    resetAutocomplete();
  }

  render() {
    const {
      submitFormFn,
      formSubmissionError,
      errorDetail,
      statusCode,
      migrateProfileFailed,
      isFetching,
    } = this.props;
    return (
      <Fragment>
        <form
          className="needs-validation"
          noValidate
        >
          <div className="personal-info-wrap">
            <h3 className="form-header">Personal Information</h3>
            <Field
              name="firstName"
              type="text"
              component={RenderField}
              label="Legal First Name*"
              validate={required}
              normalize={noPeriods}
            />
            <Field
              name="middleName"
              type="text"
              component={RenderField}
              label="Middle Name"
              normalize={noPeriods}
            />
            <Field
              name="lastName"
              type="text"
              component={RenderField}
              label="Last Name*"
              validate={required}
              normalize={noPeriods}
            />
            <Field
              name="suffix"
              type="select"
              options={suffixes}
              component={RenderField}
              label="Suffix"
              defaultSelectValue="Select Suffix"
            />
            <Field
              name="streetNumber"
              type="text"
              component={RenderField}
              label="House/Building Number*"
              validate={required}
              placeholder="Ex: 123"
              normalize={noSpecialChars}
            />
            <Field
              name="street"
              type="text"
              component={RenderField}
              label="Street Name*"
              validate={required}
              placeholder="Ex: Main Street"
            />
            <Field
              name="addressLine2"
              type="text"
              component={RenderField}
              label="Apartment/Floor/Other"
              placeholder="Ex: Apartment 2, Floor 3, etc (Optional)"
            />
            <Field
              name="city"
              type="text"
              component={RenderField}
              label="City/Town*"
              validate={[cityMaxLength, required]}
              helpText={CITY_HELP_TEXT}
            />
            <Field
              name="state"
              type="select"
              component={RenderField}
              label="State*"
              options={getAlphabeticOrder(states)}
              validate={state}
              defaultSelectValue="Select State"
            />
            <Field
              name="zip"
              type="text"
              component={RenderField}
              label="Zip Code*"
              validate={zipcode}
              normalize={normalizeZip}
            />
            <Field
              name="phone"
              type="tel"
              component={RenderField}
              label="Phone Number*"
              placeholder="(XXX) XXX-XXXX"
              validate={phone}
              normalize={normalizePhone}
              prepend="phone"
              className="has-prepend"
            />
            <Field
              name="ssn4"
              type="tel"
              component={RenderField}
              label="Social Security Number*"
              placeholder="**** (last 4 digits)"
              validate={this.state.noSsn4Value ? [this.confirmSsn4IsEntered] : [this.confirmSsn4IsEntered, ssn4]}
              normalize={normalizeSsn4}
              inputmode="tel"
            />
            <Field
              name="noSsn4"
              type="checkbox"
              component={RenderField}
              label="I do not have a Social Security Number; or I do not wish to provide my Social Security Number. (You will be asked to verify your identity on the following page.)"
              className="hanging-checkbox small"
              validate={this.confirmNoSsn4IsChecked}
            />
            <Field
              name="birthdate"
              type="tel"
              component={RenderField}
              label="Date of Birth*"
              normalize={normalizeDob}
              placeholder="MM/DD/YYYY"
              validate={validateDob}
              inputmode="tel"
            />
          </div>
          <div className="personal-info-wrap">
            <h3 className="form-header">Account Setup</h3>
            <Field
              name="email"
              type="text"
              component={RenderField}
              label="Email Address"
              autocorrect="off"
              autocapitalize="none"
              validate={email}
              prepend="mail"
              className="has-prepend"
              fieldId="sso-email"
              disabled
            />
            <div className="button-wrap">
              {isFetching ?
                <div className="loading-wrapper">
                  <BounceLoader
                    color="#084267"
                  />
                </div>
                :
                <Button
                  label="Confirm Details"
                  onClickFn={submitFormFn}
                  className="nyl-button"
                />
              }
              {migrateProfileFailed &&
                <p className="submit-error" dangerouslySetInnerHTML={{ __html: getError(errorDetail, statusCode) }} />
              }
              {formSubmissionError &&
                <p className="submit-error">Please see required fields above to continue with your verification.</p>
              }
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default MigrateExistingUserForm;
