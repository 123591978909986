/* eslint-disable no-case-declarations */
import { SELECT_GOV_ID_TYPE } from '../constants';

const defaultState = { govIdTypeSelected: false };

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_GOV_ID_TYPE:
      const { type } = action.payload;
      return {
        ...state,
        govIdTypeSelected: type,
      };
    default:
      return { ...state };
  }
};
