import {
  put,
  takeLatest,
  call,
} from 'redux-saga/effects';
import _ from 'lodash';
import queryString from 'query-string';
import { change } from 'redux-form';
import { geocodeByAddress } from 'react-places-autocomplete';
import {
  saveUserCredentials,
  saveUserName,
} from './../../services/auth';
import {
  REGISTER_USER,
  FETCH_ADDRESSES,
  SELECT_ADDRESS,
} from '../constants';
import {
  EMAIL_DUPLICATE_ERROR_MESSAGE,
  LEXID_DUPLICATE_ERROR_MESSAGE,
  PHONE_DUPLICATE_ERROR_MESSAGE,
} from '../../common/lib/constants';

import {
  registerUserSuccess,
  registerUserFailure,
  fetchAddressesSuccess,
  fetchAddressesFailure,
  selectAddressSuccess,
  selectAddressFailure,
} from '../actions';
import { gtagEvent } from '../../common/lib/analyticsEventManager';

// eslint-disable-next-line no-unused-vars
const registerSuccessMock = {
  data: {
    AccessToken: 'eyJraWQiOiJJbFc2UU8rNVdZR1VvdDBIMHJQZ3c0QWhKWlR3RGFHeGxCK080WE55K29RPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJjNjNhYjdhMS1hNTAwLTQ2ZDEtODY2OS0xZDFjOTExMWY2ZjUiLCJldmVudF9pZCI6ImM2NDM1MDk3LTYyOTUtMTFlOS1hYjliLWE5ZjlmNjAzMjE1YSIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE1NTU2NzMxMDIsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX3ZQbVNka2dFMCIsImV4cCI6MTU1NTY3NjcwMiwiaWF0IjoxNTU1NjczMTAzLCJqdGkiOiI4MjU5OTFjNS1kMWIyLTQxZTYtOTBiOC1iMzk1N2VhZjY5YTUiLCJjbGllbnRfaWQiOiIzcnQ3amdmNzZodWs3cW9scmZvZ2cxMWZlcSIsInVzZXJuYW1lIjoiYzYzYWI3YTEtYTUwMC00NmQxLTg2NjktMWQxYzkxMTFmNmY1In0.GVKu0bTIyQEYVGI146H5QasWx_a2O2ZG-e5Fo9zjGB_CIuGW1PT-zxAJKaX2uTcdXG1KwiVZqTKgb1F7ZEILJmzF5Otdf_na4rxzV0bzMQt40fmy1wj5WCqy1jHox0qv8nxoIcVOEmUL2Hsds3QeEZZwIyU5bCsu8wX-Vm5na42L7kZH3y60qWdTmHfrGO08JWgatlDx5XSdXZYOdW30AKJXKIGHJqzSmasWSA-kAYtfuu7kZEzwPjwk6F3LvFEqrWVVmj5_egXpgG-82haY8NsN4GA9FdrjPbN-FvzLXiyBG-QxtX7Uis_hW81Q30Mx6XG9m-jd14-7FE5kxP4HCA',
    ExpiresIn: 3600,
    TokenType: 'Bearer',
    RefreshToken: 'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.Xkeq7YYvwTOZS_YHsoSvs1x-zMOHf9OdFv9G68gVeHGFOkEicxOW6H3hj9dU895tnY6t1iwq6gyPOlOBv4uh3PyTS8Bowrnum5-O0cD0-_HTjCBWfQY0A1DZsx2VPHHhid70VGYXSFaVoFE4wOuYKo_KmqrKu7SIi_MK62wRzIrJQpzwTh7BXBW1xN0Xr59YELK9bqD5b6GYq7MSZVP1LVMwhsXzImjbM52_RagJK6iAeLXTXTJOI2B9dDO6LQhmeai6OZACoorWZr96N56aoPF4V8YyOvLpr9QVLWimdHqDxqq8VJzdxmA7ZH18-BmM1qzyCdwR0LHO3IWYsUyp8Q.gEwg7fnr_1EB6m5c.Mkjy7bvzuEh7w2X9LbroUYS1EMEgnE9OOKvzgkJjLLW-bZe53dPgK3axjCWDeY8HBPB_1plt8jTmH4XBp2GSyYkoHWeLzZP7Kb8jPnNs1mVMcZJic4ox5P-7AgJ_fQ_qV_uaI5qZ6mJ_Jcfzf5MxcYqqWVqBhkJ2o2xIK89C0jv6nhQGO_OADC5N0ZjWSezvEHjSf_SDUWubGbOpj47BzvZIxpu-_qbm7M5_MFfWNdsJ-K1r8dsTr-ID00_7NfHL4bOl285N1N5wFxKClQX676S2NNkdXKuR2UpNfkeoNuRmGE2iogJ2bUCyTBZrAkv-4KDwLoiXs-0fviOPrQyK85g2RPA8RGj5wciVMd2BdlqISmRVJWETj9DrELZyCvOHoaeqdc4qFri6szMceAe-712PV3xHJ2Z1vZG8PNNwjFi2pl_pKgbDhSiWq3JPiXEg2uGTk1BDKUJQHQxFT07z30PNpSMOyJyrDzTxCs1i6LCdSIkjnd4Z_Jc5z4T9jYCfgcKdcdOVwTEjiH05kKkQkGSToEgjP1MJUGOoiwHDRE_h1JzViggihtldPE21F88Cl2MG9ha0eE3u4thIhTZeUsIhPwnJWbOaIx7CAQrFt87hyrkO5bHN4hob2-XQs5B_bqBuX_-mNhBQOeEDFV-8hmPpAcCoaxIPl9PHWVGIpisu7Z8zm4iPnsmpweZDv0NMRVXaRHDKNKKzhOfJKu7deC1Hs-Eprq_paRvvWFetoOIna1g5U55h3I5UIt18-GOl_Z4An4HV5btvHsyaFj6Rbr43FlEyZPeG975I_lZT4XT283VSCljs-ie-Fn292AHwwyE6NbBMf485Qxog5Q37UsPMurVAnm5lgxBSoHWvGL08Qkq6FqpfuWUsOizAELshluRQT_6y62wXTuDFDz-uFgpWh4j5LnjDi2az_rrFdr4SlCSBLSwXU07g4TZUI8YCkUBFp9KFb4BjbMrGULdfs90hTTMQ9EfiOHQEbU4qY0iXnM6mgEU3HkNJxsbbb38Zy2_AYpDQ8qxUv7F9sJfl9Io8ES_zBtx8Ncp_Tam8HXXAaL8vmqUzxbeJItHKYL6M3X_Z1U4RsCEIbLGfpfJf0CzFu7qIW5WlGTfg8rYT1tlpX0ra75MtiU6XJzfTqTLTWEjFmauZLLAYd4zRSF_i4Udbk1xwwSJzqj-RlpWg5oAeJRtVwtKkzseZvQEeW4aIy4VzV4dplAPoKDPllGit4MWHXxNlcjE0DCYAOQzR3mhA8GV4r95iB6hqjrGqArLjY5GbvEjKcs0FqNiCQ1LEUWAuq55IR5roKSIeyMtgTqJNENdN5UXDFu7JAUyLEw.Mu-ofWStpfmcfTeFWnt0kw',
    IdToken: 'eyJraWQiOiJUeXRqcGNBM2NsXC8ydFZpcUFtUVJMRmNZM2dVR3pCRlcxNGF6YWRnZkEwdz0iLCJhbGciOiJSUzI1NiJ9.eyJ6aXAiOiIzNDc0MyIsImxhc3ROYW1lIjoiUm9zaGVsbCIsInN1YiI6ImM2M2FiN2ExLWE1MDAtNDZkMS04NjY5LTFkMWM5MTExZjZmNSIsImJpcnRoZGF0ZSI6IjA5XC8yNlwvMTk2OSIsImNpdHkiOiJLaXNzaW1tZWUiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV92UG1TZGtnRTAiLCJjb2duaXRvOnVzZXJuYW1lIjoiYzYzYWI3YTEtYTUwMC00NmQxLTg2NjktMWQxYzkxMTFmNmY1IiwiZmlyc3ROYW1lIjoiQW5kcmV3IiwiYXVkIjoiM3J0N2pnZjc2aHVrN3FvbHJmb2dnMTFmZXEiLCJldmVudF9pZCI6ImM2NDM1MDk3LTYyOTUtMTFlOS1hYjliLWE5ZjlmNjAzMjE1YSIsInBob25lIjoiKDQwNykgMzQ4LTc1NDEiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTU1NTY3MzEwMiwiYWRkcmVzc0xpbmUxIjoiMjU5MSBCcnVuc3dpY2sgQ3QiLCJzdGF0ZSI6IkZMIiwiZXhwIjoxNTU1Njc2NzAyLCJpYXQiOjE1NTU2NzMxMDIsImVtYWlsIjoiZXZhbitzc291YXRmaW5hbEByb3NlZGlnaXRhbC5jbyJ9.l_AGCiDzGM_TUDfYr_9Vn3BTNmmbIn48EtRP4p3GEcKLyk60CA71k64ShIH_i2EY2LGYsxI6f4psIIvCNQAycfXQhhX72l07Gmf09mrcHC8ySPD3plMr6X6i70pdwmOacR2Mw8SUpumWY188N0lRHrzcypjwQaedczhZ_L-EGhR4THC7CQU4p9wSEyuxFTvaL6uAZyf7cWugAEZs5dceFVjKTgjdIH2objXmDtdB3XYtKeMFP9QxQqKmDs3f0uQmgrhfW7XmCFxj0xbIv_EohjjvpRWayRmqCSWC4kwY1MuS6UcUgh6Bnwjcen4n1g21SCOAxSPZLZE9ULK1yVJWyQ',
    statusCode: 200,
    status: 'SUCCESS',
  },
};

// eslint-disable-next-line no-unused-vars
const registerHardFailMock = {
  data: {
    piiScore: {
      score: '0.0',
      hardFail: true,
      softFail: false,
      pass: false,
    },
    statusCode: 400,
    status: 'ERROR',
  },
};

// eslint-disable-next-line no-unused-vars
const softFailMock = {
  data: {
    score: '58.0',
    hardFail: false,
    softFail: true,
    pass: false,
    statusCode: 200,
    status: 'SUCCESS',
  },
};

const validateParsedAddress = (address) => {
  return address &&
    ((address.streetNumber && address.street) || address.premise) &&
    address.city &&
    address.state &&
    address.zip;
};
const parseAddress = (address) => {
  const addressComponents = _.get(address, '0.address_components', false);
  const parsedAddress = {
    streetNumber: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    premise: null,
  };
  if (addressComponents) {
    addressComponents.forEach((component) => {
      if (component.types.includes('street_number')) { // route, locality, administrative_area_level_1, postal_code
        parsedAddress.streetNumber = component.short_name;
      } else if (component.types.includes('route')) {
        parsedAddress.street = component.short_name;
      } else if (component.types.includes('locality')) {
        parsedAddress.city = component.short_name;
      } else if (component.types.includes('sublocality')) {
        parsedAddress.city = component.short_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        parsedAddress.state = component.short_name;
      } else if (component.types.includes('postal_code')) {
        parsedAddress.zip = component.short_name;
      } else if (component.types.includes('premise')) {
        parsedAddress.premise = component.short_name;
      }
    });
  }
  return parsedAddress;
};

const createRegisterSagas = (api) => {
  function* registerUserSaga(action) {
    const {
      registerUserForm,
      selectedAddressParsed,
      push,
    } = action.payload;
    try {
      const queryParams = queryString.parse(window.location.search);
      const callbackUri = _.get(queryParams, 'callbackUri', false);
      const clientId = _.get(queryParams, 'clientId', false);
      const callbackUriStr = callbackUri ? `?callbackUri=${callbackUri}&clientId=${clientId}` : '';
      const response = yield call(api.registerUser, {
        registerUserForm,
        callbackUri,
        selectedAddressParsed,
      });
      const statusCode = _.get(response, 'data.data.statusCode', false);
      const idvPass = _.get(response, 'data.data.AccessToken', false);
      const idvSoftFail = _.get(response, 'data.data.softFail', false);
      const idvHardFail = _.get(response, 'data.data.hardFail', false);
      const phoneDuplicate = _.get(response, 'data.data.phoneDuplicate', false);
      const emailDuplicate = _.get(response, 'data.data.emailDuplicate', false);
      const lexIdDuplicate = _.get(response, 'data.data.lexIdDuplicate', false);
      const duplicatedEmail = _.get(response, 'data.data.duplicatedEmail', '');
      if (statusCode === 200 && idvPass) {
        const accessToken = _.get(response, 'data.data.AccessToken', null);
        const refreshToken = _.get(response, 'data.data.RefreshToken', null);
        const identityToken = _.get(response, 'data.data.IdToken', null);
        const expiresIn = _.get(response, 'data.data.ExpiresIn', null);
        yield put(registerUserSuccess({
          accessToken,
          refreshToken,
          identityToken,
          expiresIn,
        }));

        // FETCH USER DATA
        const userData = yield call(api.fetchProfile);
        const userDataParsed = _.get(userData, 'data.data.attributes', false);
        const { firstName, lastName } = userDataParsed;
        yield put(registerUserSuccess({
          accessToken,
          refreshToken,
          identityToken,
          expiresIn,
          userDataParsed,
        }));
        saveUserCredentials({
          accessToken,
          refreshToken,
          identityToken,
          expiresIn,
        });
        saveUserName({
          firstName,
          lastName,
        });

        if (!userDataParsed['custom:phone_verification'] || userDataParsed['custom:phone_verification'] === 'N') {
          gtagEvent('DC-5139115/colle0/colle00a+standard');
          push(`/idv/otp${callbackUri ? callbackUriStr : ''}`);
          yield put(registerUserFailure({ statusCode }));
        }
      } else if (idvSoftFail || idvHardFail) {
        if (idvHardFail) {
          gtagEvent('DC-5139115/colle0/colle00a+standard');
          push(`/idv/failed${callbackUri ? callbackUriStr : ''}`);
          yield put(registerUserFailure({ statusCode }));
        } else if (idvSoftFail) {
          gtagEvent('DC-5139115/colle0/colle00a+standard');
          push(`/idv/otp${callbackUri ? callbackUriStr : ''}`);
          yield put(registerUserFailure({ statusCode }));
        }
      } else if (phoneDuplicate) {
        const errorDetail = PHONE_DUPLICATE_ERROR_MESSAGE;
        yield put(registerUserFailure(statusCode, errorDetail, duplicatedEmail));
      } else if (emailDuplicate) {
        const errorDetail = EMAIL_DUPLICATE_ERROR_MESSAGE;
        yield put(registerUserFailure(statusCode, errorDetail, duplicatedEmail));
      } else if (lexIdDuplicate) {
        const errorDetail = LEXID_DUPLICATE_ERROR_MESSAGE;
        push(`/user-duplicated?clientId=${clientId}`);
        yield put(registerUserFailure(statusCode, errorDetail, duplicatedEmail));
      } else {
        const errorDetail = _.get(response, 'data.errors[0].detail', false);
        const status = _.get(response, 'status', false);
        yield put(registerUserFailure(status, errorDetail));
      }
    } catch (e) {
      const errorDetail = _.get(e.response, 'data.errors.0.message', false);
      yield put(registerUserFailure({ errorDetail }));
    }
  }

  function* watchRegisterUser() {
    yield takeLatest(REGISTER_USER, registerUserSaga);
  }

  function* fetchAddressesSaga(action) {
    const { input } = action.payload;
    try {
      const response = yield call(api.fetchPlaces, { input });
      const statusCode = _.get(response, 'status', false);
      if (statusCode === 200) {
        yield put(fetchAddressesSuccess({ response }));
      } else {
        const errorDetail = _.get(response, 'data.errors.0.message', false);
        yield put(fetchAddressesFailure({
          statusCode,
          errorDetail,
        }));
      }
    } catch (e) {
      const errorDetail = _.get(e.response, 'data.errors.0.message', false);
      yield put(fetchAddressesFailure({ errorDetail }));
    }
  }

  function* watchFetchAddresses() {
    yield takeLatest(FETCH_ADDRESSES, fetchAddressesSaga);
  }

  function* selectAddressSaga(action) {
    const { address } = action.payload;
    try {
      const response = yield call(geocodeByAddress, address);
      const parsedAddress = parseAddress(response);
      if (validateParsedAddress(parsedAddress)) {
        yield put(selectAddressSuccess({
          address,
          parsedAddress,
        }));
        yield put(change('register', 'address', address));
      } else {
        yield put(selectAddressFailure({ error: 'Your address must have a street number, street name, city, state and zipcode. Please try again.' }));
      }
    } catch (error) {
      yield put(selectAddressFailure({ error }));
    }
  }

  function* watchSelectAddress() {
    yield takeLatest(SELECT_ADDRESS, selectAddressSaga);
  }
  return {
    watchRegisterUser,
    watchFetchAddresses,
    watchSelectAddress,
  };
};

export default createRegisterSagas;
