/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-string-refs */
import React, { Component } from 'react';
import classNames from 'classnames';
import uuid from 'uuid/v4';
import Spinner from 'react-spinkit';
import PlacesAutocomplete from 'react-places-autocomplete';
import DatePicker from '../../../common/components/Form/DatePicker';
import './RenderField.css';

// autotab onKeyUp is already implemented to field type 'ssn' can
// be added to the other field types as necessary
const autotabInputs = (event, commonAncestorClassName) => {
  const commonAncestorNode = event.target.closest(`.${commonAncestorClassName}`);
  const currentInput = event.target;
  const relatedInputs = Array.from(commonAncestorNode.querySelectorAll('input'));
  const nextInput = relatedInputs[relatedInputs.indexOf(currentInput) + 1];
  if (relatedInputs.indexOf(currentInput) < relatedInputs.length - 1 && currentInput.value.length === Number(currentInput.getAttribute('maxlength'))) {
    nextInput.focus();
  }
};

export default class RenderField extends Component {
  constructor(props) {
    super(props);
    // this state below also seems unused
    this.state = { address: '' };

    this.onAddressChange = this.onAddressChange.bind(this);
  }

  onAddressChange(address) {
    const { input } = this.props;
    const { onChange } = input;
    this.setState({ address });
    onChange(address);
  }

  render() {
    const {
      containerName,
      input,
      inputmode,
      fieldId,
      label,
      name,
      type,
      placeholder,
      helpText,
      helpTextId,
      className,
      options,
      prepend,
      append,
      onSelect,
      readOnly,
      disabled,
      addressErrorDetail,
      defaultSelectValue,
      meta:
        {
          touched,
          error,
          warning,
        },
    } = this.props;

    switch (type) {
      case 'checkbox':
        return (
          <div className={classNames(className)}>
            <div className="checkbox checkbox-div">
              <input
                {...input}
                placeholder={label}
                type={type}
                className="form-check-input"
                autoComplete="none"
              />
              {/* will we be implementing any kind of form controls here? */}
              <label className={classNames(
                'form-check-label',
                { error: touched && error && error !== null }
                )
              }
              >{label}
              </label>
              <span
                className={classNames('checkmark', { checked: input.value })}
                // shouldn't we be using
                // ref={(c) => { this.checkmark = c }} instead of ref string literals?
                ref="checkmark"
              />
            </div>
          </div>
        );
      case 'radio':
      case 'password':
      case 'tel':
      case 'text':
        return (
          <div className={classNames(
            'form-group',
            className
          )}
          >
            {label && <label>{label}</label>}
            <div className={classNames(
              'input',
              { error: touched && error },
              'input-group'
            )}
            >
              {prepend &&
              <div className="input-group-prepend">
                <span className={`input-group-text ${disabled ? 'disabled' : ''}`}>
                  <span className={`icon nyl-sso-icon-${prepend}`} />
                </span>
              </div>
              }
              {readOnly ?
                <input
                  id={fieldId}
                  className={classNames('form-control-plaintext', { masktext: input.name === 'ssn4' })}
                  aria-describedby="emailHelp"
                  {...input}
                  placeholder={placeholder}
                  type={type}
                  autoComplete="none"
                  readOnly
                  inputMode={inputmode || 'text'}
                />
                :
                <input
                  id={fieldId}
                  className={classNames('form-control', { masktext: input.name === 'ssn4' })}
                  aria-describedby="emailHelp"
                  {...input}
                  placeholder={placeholder}
                  type={type}
                  autoComplete="none"
                  disabled={disabled}
                  inputMode={inputmode || 'text'}
                />

              }
              {append &&
              <div className="input-group-append">
                <span className="input-group-text">
                  <span className={`icon nyl-sso-icon-${append}`} />
                </span>
              </div>
              }
            </div>
            {helpText && <small id={helpTextId} className="form-text text-muted">{helpText}</small>}
            {touched && error && <div className="is-error invalid-feedback">{error}</div>}
          </div>
        );

      case 'ssn':
        return (
          <div className={classNames(
            'individual-ssn-container',
            className
          )}
          >
            <input
              className={classNames('input-individual-ssn', className)}
              onKeyUp={(event) => autotabInputs(event, containerName)}
              placeholder={placeholder}
              type="tel"
              maxLength="1"
              id={fieldId}
              name={name}
              ref={name}
              autoComplete="none"
              {...input}
            />
            {touched &&
              ((error && <span>{error}</span>) ||
                (warning && <span>{warning}</span>))}
          </div>
        );

      case 'places-autocomplete':
        return (
          <PlacesAutocomplete
            value={this.state.address}
            onChange={this.onAddressChange}
            placeholder={placeholder}
            onSelect={onSelect}
            type={type}
            searchOptions={{ types: ['address'] }}
          >
            {({
             getInputProps, suggestions, getSuggestionItemProps, loading,
            }) => (
              <div className={classNames(
                            'form-group',
                            className
                          )}
              >
                {label && <label>{label}</label>}
                <div className={classNames(
                              'input',
                              { error: touched && error !== null },
                              'input-group'
                            )}
                >
                  <input
                    id={fieldId}
                    aria-describedby="addressAutocomplete"
                    autoComplete="none"
                    {...getInputProps({
                                  placeholder,
                                  className: 'form-control',
                                })}
                  />
                </div>
                <div className="autocomplete-results center">
                  {loading && <Spinner className="spinner" name="ball-spin-fade-loader" />}
                  {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? 'active'
                                : '';
                              // inline style for demonstration purpose
                              const style = {
                                backgroundColor: (suggestion.active ? '#fafafa' : '#ffffff'),
                                cursor: 'pointer',
                              };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <a href="#" className="list-group-item list-group-item-action">{suggestion.description}</a>
                                </div>
                              );
                            })}
                </div>
                {helpText && <small id={helpTextId} className="form-text text-muted">{helpText}</small>}
                {addressErrorDetail && <div className="is-error invalid-feedback">{addressErrorDetail}</div>}
              </div>
            )}
          </PlacesAutocomplete>
        );

      case 'places-autocomplete-vtwo':
        return (
          <div className={classNames(
            'form-group',
            className
          )}
          >
            {label && <label>{label}</label>}
            <div className={classNames(
              'input',
              { error: touched && error !== null },
              'input-group'
            )}
            >
              {prepend &&
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <span className={`icon nyl-sso-icon-${prepend}`} />
                </span>
              </div>
              }
              <input
                id={fieldId}
                className="form-control"
                aria-describedby="autocompleteHelp"
                {...input}
                placeholder={placeholder}
                type={type}
                autoComplete="none"
              />
              {append &&
              <div className="input-group-append">
                <span className="input-group-text">
                  <span className={`icon nyl-sso-icon-${append}`} />
                </span>
              </div>
              }
              {helpText && <small id={helpTextId} className="form-text text-muted">{helpText}</small>}
              {touched && error && <div className="is-error invalid-feedback">{error}</div>}
            </div>
          </div>
        );
      case 'select':
        return (
          <div className={classNames(
            'form-group',
            { error: touched && error },
            className
          )}
          >
            <label>{label}</label>
            <div>
              <select
                className="custom-select"
                {...input}
              >
                <option defaultValue value={0}>{defaultSelectValue}</option>
                {options && options.length > 0 &&
                  options.map((option) =>
                    <option key={uuid()} value={option}>{option}</option>)
                }
              </select>
              {touched && error && <div className="is-error invalid-feedback">{error}</div>}
            </div>
          </div>
        );
      case 'datepicker':
        return (
          <div className={classNames(
            'form-group',
            className
          )}
          >
            <label>{label}</label>
            <div className={classNames(
              'input',
              { error: touched && error !== null },
              'input-group'
              )}
            >
              {prepend &&
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <span className={`icon nyl-sso-icon-${prepend}`} />
                  </span>
                </div>
              }
              <DatePicker
                {...input}
              />

              {touched && error && <div className="error_str invalid-feedback">{error}</div>}
            </div>
          </div>
        );
      default:
        return (
          <div className={classNames(
            'form-group',
            className
          )}
          >
            <label>{label}</label>
            <div className={classNames('input', { error: touched && error !== null })}>
              <input
                className="form-control"
                {...input}
                placeholder={placeholder}
                type={type}
                autoComplete="none"
              />
              {touched && error && <div className="error_str">{error}</div>}
            </div>
          </div>
        );
    }
  }
}
