import jwtDecode from 'jwt-decode';
import { Base64 } from 'js-base64';
import {
  NYL_SSO_ACCESS_TOKEN,
  NYL_SSO_IDENTITY_TOKEN,
  NYL_SSO_REFRESH_TOKEN,
  NYL_SSO_IDENTITY,
  NYL_SSO_EXPIRES_IN,
  NYL_SSO_FIRST_NAME,
  NYL_SSO_LAST_NAME,
} from '../redux/constants';

import {
  setCookieItem,
  getCookieItem,
  removeCookieItem,
} from './cookies';

export const saveUserName = ({
  firstName,
  lastName,
}) => {
  if (firstName) {
    setCookieItem(NYL_SSO_FIRST_NAME, firstName);
  }
  if (lastName) {
    setCookieItem(NYL_SSO_LAST_NAME, lastName);
  }
};

export const saveUserCredentials = ({
  accessToken,
  identityToken,
  refreshToken,
  expiresIn,
}) => {
  const identity = jwtDecode(identityToken);
  setCookieItem(NYL_SSO_ACCESS_TOKEN, accessToken);
  setCookieItem(NYL_SSO_IDENTITY_TOKEN, identityToken);
  setCookieItem(NYL_SSO_REFRESH_TOKEN, refreshToken);
  setCookieItem(NYL_SSO_EXPIRES_IN, expiresIn);
  setCookieItem(NYL_SSO_IDENTITY, Base64.encode(JSON.stringify(identity)));
};

export const fetchUser = () => {
  const identityPayload = {};
  identityPayload.accessToken = getCookieItem(NYL_SSO_ACCESS_TOKEN);
  identityPayload.identityToken = getCookieItem(NYL_SSO_IDENTITY_TOKEN);
  identityPayload.refreshToken = getCookieItem(NYL_SSO_REFRESH_TOKEN);
  identityPayload.identity = getCookieItem(NYL_SSO_IDENTITY);
  identityPayload.firstName = getCookieItem(NYL_SSO_FIRST_NAME);
  identityPayload.lastName = getCookieItem(NYL_SSO_LAST_NAME);
  identityPayload.expiresIn = getCookieItem(NYL_SSO_EXPIRES_IN);
  if (identityPayload.identity) {
    identityPayload.identity = JSON.parse(Base64.decode(identityPayload.identity));
  }
  return identityPayload;
};

export const flushUser = () => {
  removeCookieItem(NYL_SSO_ACCESS_TOKEN);
  removeCookieItem(NYL_SSO_IDENTITY_TOKEN);
  removeCookieItem(NYL_SSO_REFRESH_TOKEN);
  removeCookieItem(NYL_SSO_EXPIRES_IN);
  removeCookieItem(NYL_SSO_IDENTITY);
};
