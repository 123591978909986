/* eslint-disable no-case-declarations */
import _ from 'lodash';
import jwtDecode from 'jwt-decode';
import {
  REGISTER_USER_SUCCESS,
  LOGIN_USER_SUCCESS,
  REQUEST_OTP_SUCCESS,
  REQUEST_OTP,
  REQUEST_OTP_COOLDOWN_EXPIRE,
  REQUEST_OTP_HIDE_ANIMATION,
  SAVE_USER_PROFILE,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  VERIFY_SSN,
  VERIFY_SSN_SUCCESS,
  VERIFY_SSN_FAILURE,
} from '../constants';

const defaultState = {
  identityToken: null,
  accessToken: null,
  refreshToken: null,
  identity: {},
  asi: null,
  userData: { phone: '' },
  otpCooldown: false,
  otpRequestType: null,
  selectedAddress: null,
  verifyOtpIsFetching: false,
  verifySsnIsFetching: false,
  showOtpRequestAnimation: false,
};


// custom:phone_verification: "N"
// custom:ssn_verification: "-"
// custom:verified: "N"

export default (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER_USER_SUCCESS:
      const {
        accessToken,
        identityToken,
        refreshToken,
        userDataParsed,
      } = action.payload;

      const decodedIdentityToken = jwtDecode(identityToken);
      return {
        ...state,
        identityToken,
        accessToken,
        refreshToken,
        identity: decodedIdentityToken,
        userData: userDataParsed,
      };

    case LOGIN_USER_SUCCESS:
      const accessTokenLogin = _.get(action, 'payload.accessToken', false);
      const identityTokenLogin = _.get(action, 'payload.identityToken', false);
      const refreshTokenLogin = _.get(action, 'payload.refreshToken', false);
      const decodedIdentityTokenLogin = jwtDecode(identityTokenLogin);
      return {
        ...state,
        identityToken: identityTokenLogin,
        accessToken: accessTokenLogin,
        refreshToken: refreshTokenLogin,
        identity: decodedIdentityTokenLogin,
      };
    case REQUEST_OTP_HIDE_ANIMATION:
      return {
        ...state,
        showOtpRequestAnimation: false,
      };
    case REQUEST_OTP:
      const {
        type,
        showOtpRequestAnimation,
      } = action.payload;
      return {
        ...state,
        otpRequestType: type,
        showOtpRequestAnimation: showOtpRequestAnimation || false,
        otpCooldown: true,
      };
    case REQUEST_OTP_SUCCESS:
      const { asi } = action.payload;
      return {
        ...state,
        asi,
      };
    case REQUEST_OTP_COOLDOWN_EXPIRE:
      return {
        ...state,
        otpCooldown: false,
      };
    case VERIFY_OTP:
      return {
        ...state,
        verifyOtpIsFetching: true,
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        verifyOtpIsFetching: false,
      };
    case VERIFY_OTP_FAILURE:
      return {
        ...state,
        verifyOtpIsFetching: false,
      };
    case VERIFY_SSN:
      return {
        ...state,
        verifySsnIsFetching: true,
      };
    case VERIFY_SSN_SUCCESS:
      return {
        ...state,
        verifySsnIsFetching: false,
      };
    case VERIFY_SSN_FAILURE:
      return {
        ...state,
        verifySsnIsFetching: false,
      };
    case SAVE_USER_PROFILE:
      const { userData } = action.payload;
      const addressIso = _.get(userData, 'address_iso', false);
      let selectedAddress = null;
      if (addressIso) {
        selectedAddress = addressIso.split('|').join(' ');
      }
      return {
        ...state,
        userData,
        selectedAddress,
      };
    default:
      return { ...state };
  }
};
