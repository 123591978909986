import { createSelector } from 'reselect';
import _ from 'lodash';

const forgotPasswordFormSelector = (state) => state.form;

const forgotPasswordSelector = createSelector(
  forgotPasswordFormSelector,
  (forgotPasswordForm) => {
    return _.get(forgotPasswordForm, 'forgotPassword.values', false);
  }
);

export const getForgotPasswordFormValues = (state) => forgotPasswordSelector(state);
