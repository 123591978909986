import React, {
  Component,
  Fragment,
} from 'react';
import { connect } from 'react-redux';
import {
  reduxForm,
  formValueSelector,
} from 'redux-form';
import { getConfirmResetPasswordFormValues } from '../../redux/selectors/confirmResetPasswordSelector';
import { confirmResetPassword } from '../../redux/actions';
import {
  isEmpty,
  validatePassword,
} from '../../common/lib/validation';
import ConfirmResetPasswordForm from './ConfirmResetPasswordForm';

const formSelector = formValueSelector('confirmResetPassword');

require('../../../style/index.css');
require('./confirmResetPassword.css');

const mapStateToProps = (state) => ({
  password: formSelector(state, 'password'),
  confirmResetPasswordForm: getConfirmResetPasswordFormValues(state),
  confirmResetPasswordFailed: state.confirmResetPasswordReducer.confirmResetPasswordFailed,
  errorDetail: state.confirmResetPasswordReducer.errorDetail,
  statusCode: state.confirmResetPasswordReducer.statusCode,
  confirmResetPasswordSuccess: state.confirmResetPasswordReducer.confirmResetPasswordSuccess,
});

const mapDispatchToProps = { confirmResetPassword };

const confirmNewPassword = (password, confirmPassword) => {
  return !isEmpty(password) && !isEmpty(confirmPassword) && password === confirmPassword;
};

const isValid = (values) => {
  let valid = true;
  if (validatePassword(values.password)
    || !confirmNewPassword(values.password, values.confirmPassword)) {
    valid = false;
  }
  return valid;
};

@connect(mapStateToProps, mapDispatchToProps)
class ConfirmResetPasswordView extends Component {
  constructor(props) {
    super(props);
    this.handleConfirmResetPasswordSubmit = this.handleConfirmResetPasswordSubmit.bind(this);
    this.state = { formSubmissionError: false };
  }

  handleConfirmResetPasswordSubmit(e) {
    e.preventDefault();
    const {
      confirmResetPasswordForm,
      // eslint-disable-next-line no-shadow
      confirmResetPassword,
    } = this.props;
    if (isValid(confirmResetPasswordForm)) {
      confirmResetPassword(confirmResetPasswordForm);
      this.setState({ formSubmissionError: false });
    } else {
      this.setState({ formSubmissionError: true });
    }
  }

  render() {
    const {
      confirmResetPasswordFailed,
      confirmResetPasswordSuccess,
      errorDetail,
      statusCode,
      password,
    } = this.props;
    const { formSubmissionError } = this.state;
    return (
      <Fragment>
        <div className="confirm-reset-password row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-8 col-sm-10">
            {!confirmResetPasswordSuccess &&
            <ConfirmResetPasswordForm
              submitFormFn={this.handleConfirmResetPasswordSubmit}
              confirmResetPasswordSuccess={confirmResetPasswordSuccess}
              confirmResetPasswordFailed={confirmResetPasswordFailed}
              errorDetail={errorDetail}
              statusCode={statusCode}
              formSubmissionError={formSubmissionError}
              password={password}
            />
            }
            {confirmResetPasswordSuccess &&
              <div className="alert alert-success mt-5">
                <h4 className="alert-heading text-center text-uppercase">Success!</h4>
                <p className="mt-2 text-center">Your password has been reset.</p>
              </div>
            }

          </div>
        </div>
      </Fragment>
    );
  }
}

export default reduxForm({ form: 'confirmResetPassword' })(ConfirmResetPasswordView);
