import React, { Fragment } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
// import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { normalizeNums } from '../../common/lib/validation';
import RenderField from '../../common/components/Form/RenderField';
import Button from '../../common/components/Button';

const SsnForm = (props) => {
  const {
    submitSsnFn,
    verifySsnIsFetching,
  } = props;
  return (
    <Fragment>
      <form
        onSubmit={submitSsnFn}
      >
        <div className="verify-ssn-wrap">
          <h3 className="verify-ssn-header">Confirm Identity</h3>
          <p className="verify-ssn-text" >Please enter the last 4 digits of your social security number. This helps us confirm your identity and secure your account.</p>
          <div className="ssn-container">
            <h3>XXX - XX - </h3>
            <div className="ssn-input-group">
              <Field
                name="ssn1"
                type="ssn"
                component={RenderField}
                containerName="ssn-input-group"
                normalize={normalizeNums}
              />
              <Field
                name="ssn2"
                type="ssn"
                component={RenderField}
                containerName="ssn-input-group"
                normalize={normalizeNums}
              />
              <Field
                name="ssn3"
                type="ssn"
                component={RenderField}
                containerName="ssn-input-group"
                normalize={normalizeNums}
              />
              <Field
                name="ssn4"
                type="ssn"
                component={RenderField}
                containerName="ssn-input-group"
                normalize={normalizeNums}
              />
            </div>
          </div>
          <div className="button-wrap">
            {verifySsnIsFetching ?
              <div className="loading-wrapper">
                <BounceLoader
                  color="#084267"
                />
              </div>
              :
              <Button
                label="Confirm"
                type="submit"
                className="nyl-button"
              />
            }
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default SsnForm;
