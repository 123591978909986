import ReactGA from 'react-ga';

export const pageview = (url) => {
  ReactGA.pageview(url);
};

export const event = ({
  action, category, label, value,
}) => {
  ReactGA.event({
    action,
    category,
    label,
    value,
  });
};

export const gtagEvent = (sendTo) => {
  if (window && window.gtag) {
    window.gtag('event', 'conversion', {
      allow_custom_scripts: true,
      send_to: sendTo,
    });
  }
};
