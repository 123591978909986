/* eslint-disable react/no-string-refs */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/require-default-props */
/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DateTime from 'react-datetime';

import './DatePicker.css';

const formatValue = (value) => {
  return value ? moment(value).format('MM/DD/YYYY') : '';
};

class DatePicker extends React.Component {
  openCalendar() {
    this.refs.datePicker.openCalendar();
  }
  handleChange = (newValue) => {
    this.props.onChange(newValue.toISOString());
  };

  checkDateIsValid = (current) => {
    const today = moment();
    return current.isBefore(today);
  };

  render() {
    const {
      tabIndex,
      value,
      noToday,
    } = this.props;
    return (
      <div className="date-picker__container">
        <DateTime
          timeFormat={false}
          closeOnSelect
          ref="datePicker"
          isValidDate={this.checkDateIsValid}
          inputProps={{
            readOnly: true,
            className: 'date-picker__input form-control',
            value: formatValue(value, noToday),
            tabIndex,
          }}
          value={moment(value)}
          onChange={this.handleChange}
        />
        <div
          className="date-picker__icon-hook"
          onClick={() => {
            this.openCalendar();
          }}
        />
      </div>
    );
  }
}
DatePicker.propTypes = {
  tabIndex: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  noToday: PropTypes.bool,
};
DatePicker.defaultProps = {
  isValidDate: () => true,
  timezone: null,
};

export const makeDatePicker = ({
  /* eslint-disable react/prop-types */
  input,
  ...options
  /* eslint-enable react/prop-types */
}) => (
  <DatePicker
    {...input}
    {...options}
  />
);

export default DatePicker;
