/* eslint-disable no-shadow */
import React, {
  Component,
  Fragment,
} from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import ConfirmAccount from './ConfirmAccount';
import EnterCodeForm from './EnterCodeForm';
import {
  requestOtp,
  verifyOtp,
} from '../../redux/actions';

const formSelector = formValueSelector('otp-code-confirmation');

require('../../../style/index.css');
require('./index.css');

const mapStateToProps = (state) => ({
  userData: state.commonReducer.userData,
  otp: formSelector(state, 'otp'),
  asi: state.commonReducer.asi,
  otpRequestType: state.commonReducer.otpRequestType,
  verifyOtpIsFetching: state.commonReducer.verifyOtpIsFetching,
  verifySsnIsFetching: state.commonReducer.verifySsnIsFetching,
});

const mapDispatchToProps = {
  requestOtp,
  verifyOtp,
};

@connect(mapStateToProps, mapDispatchToProps)

class ConfirmOTPView extends Component {
  constructor(props) {
    super(props);
    this.requestOtp = this.requestOtp.bind(this);
    this.verifyOtp = this.verifyOtp.bind(this);
    this.state = {
      userHasRequestedOTP: false,
      userVerifyFailed: false,
      errorState: null,
    };
  }

  onVerifyFailed() {
    const { userVerifyFailed } = this.state;
    this.setState({ userVerifyFailed: true });
    return userVerifyFailed;
  }

  requestOtp(type, e, showOtpRequestAnimation = false) {
    const { requestOtp } = this.props;
    if (e) {
      e.preventDefault();
    }
    requestOtp({
      type,
      showOtpRequestAnimation,
    });
    if (!this.state.userHasRequestedOTP) {
      this.setState({ userHasRequestedOTP: true });
    }
  }

  verifyOtp() {
    const {
      verifyOtp,
      history,
      otp,
      asi,
      otpRequestType,
    } = this.props;
    if (otp && otp.length === 6) {
      verifyOtp(asi, otp, otpRequestType, history.push, this.onVerifyFailed);
      this.setState({ errorState: null });
    } else {
      this.setState({ errorState: 'You must enter a PIN to proceed.' });
    }
  }

  render() {
    const {
      userData,
      accessToken,
      location,
      verifyOtpIsFetching,
    } = this.props;

    const {
      userHasRequestedOTP,
      errorState,
    } = this.state;

    return (
      <Fragment>
        <div key={location.pathname} className="confirm-otp row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-8 col-sm-10">
            {userHasRequestedOTP ?
              <EnterCodeForm
                phoneNumber={userData.phone}
                requestOtp={this.requestOtp}
                verifyOtp={this.verifyOtp}
                accessToken={accessToken}
                errorState={errorState}
                userVerifyFailed={this.userVerifyFailed}
                verifyOtpIsFetching={verifyOtpIsFetching}
              />
              :
              <ConfirmAccount
                phoneNumber={userData.phone}
                requestOtp={this.requestOtp}
                accessToken={accessToken}
              />
            }
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ConfirmOTPView;
