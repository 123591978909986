import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import registerReducer from './reducers/registerReducer';
import verifyEmailReducer from './reducers/verifyEmailReducer';
import loginReducer from './reducers/loginReducer';
import forgotPasswordReducer from './reducers/forgotPasswordReducer';
import confirmResetPasswordReducer from './reducers/confirmResetPasswordReducer';
import updateProfileReducer from './reducers/updateProfileReducer';
import migrateProfileReducer from './reducers/migrateProfileReducer';
import selectGovIdTypeReducer from './reducers/selectGovIdTypeReducer';
import commonReducer from './reducers/commonReducer';

export default combineReducers({
  routing,
  registerReducer,
  loginReducer,
  forgotPasswordReducer,
  confirmResetPasswordReducer,
  updateProfileReducer,
  verifyEmailReducer,
  migrateProfileReducer,
  selectGovIdTypeReducer,
  commonReducer,
  form: formReducer,
});
