import React, { PureComponent } from 'react';
import './RedirectTimer.css';

class RedirectTimer extends PureComponent {
  componentDidMount() {
    const {
      redirectUri,
      timer,
    } = this.props;
    window.setTimeout(() => {
      window.location.href = redirectUri;
    }, timer);
  }

  render() {
    const {
      redirectUri,
      message,
    } = this.props;

    return (
      <div className="col-xl-7 col-lg-8 col-md-8 col-sm-10">
        <div className="redirect-timer row justify-content-center">
          <p className="redirect-message">{message}</p>
          <a href={redirectUri}>Go now</a>
        </div>
      </div>
    );
  }
}

export default RedirectTimer;
