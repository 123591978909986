/* eslint-disable no-shadow */
import {
  put,
  takeLatest,
  call,
} from 'redux-saga/effects';
import _ from 'lodash';
import queryString from 'query-string';
import {
  UPDATE_PROFILE,
  SIGN_OUT,
} from '../constants';
import {
  fetchUser,
  flushUser,
  saveUserName,
} from './../../services/auth';
import {
  updateProfileSuccess,
  updateProfileFailure,
  saveUserProfile,
  signOutFailure,
  signOutSuccess,
} from '../actions';
import { PHONE_DUPLICATE_ERROR_MESSAGE } from '../../common/lib/constants';

const createUpdateProfileSagas = (api) => {
  function* signOutSaga(action) {
    const { push } = action.payload;
    try {
      const queryParams = queryString.parse(window.location.search);
      const clientId = _.get(queryParams, 'clientId', false);
      const redirectUri = _.get(queryParams, 'redirectUri', false);
      const accessToken = _.get(queryParams, 'accessToken', false);
      const response = yield call(api.signOut, { accessToken });
      const statusCode = _.get(response, 'data.data.statusCode', false);
      if (statusCode === 200) {
        flushUser();
        if (redirectUri) {
          window.location.replace(redirectUri);
        } else {
          push(`/login?clientId=${clientId}&callbackUri=${window.location.origin}/update-profile&secondCallbackUri=${window.location.origin}/update-profile`);
        }
        yield put(signOutSuccess());
      } else {
        console.log('response to sign out failed', response);
        const errorDetail = _.get(response, 'data.errors[0].detail', false);
        const statusCode = _.get(response, 'status', false);
        yield put(signOutFailure({
          statusCode,
          errorDetail,
        }));
      }
    } catch (e) {
      console.error('signOutSaga error', e);
      const statusCode = 400;
      const errorDetail = _.get(e.response, 'data.errors.0.message', false);
      yield put(signOutFailure({
        statusCode,
        errorDetail,
      }));
    }
  }

  function* watchSignOut() {
    yield takeLatest(SIGN_OUT, signOutSaga);
  }

  function* updateProfileSaga(action) {
    const {
      updateProfileForm,
      push,
    } = action.payload;
    try {
      const queryParams = queryString.parse(window.location.search);
      let accessToken = _.get(queryParams, 'accessToken', false);
      const callbackUri = _.get(queryParams, 'callbackUri', false);
      const callbackUriStr = callbackUri ? `?callbackUri=${callbackUri}` : '';
      updateProfileForm.verify = false;
      const response = yield call(api.updateProfile, {
        updateProfileForm,
        accessToken,
      });
      const statusCode = _.get(response, 'data.data.statusCode', false);
      const idvPass = _.get(response, 'data.data.pass', false);
      const softFail = _.get(response, 'data.data.softFail', false);
      const hardFail = _.get(response, 'data.data.hardFail', false);
      const phoneDuplicate = _.get(response, 'data.data.phoneDuplicate', false);
      const { refreshToken } = fetchUser();
      const tokenResponse = yield call(api.refreshUserSession, { refreshToken });
      accessToken = _.get(tokenResponse, 'data.data.AccessToken', null);
      const identityToken = _.get(tokenResponse, 'data.data.IdToken', null);
      const expiresIn = _.get(tokenResponse, 'data.data.ExpiresIn', null);
      const userData = yield call(api.fetchProfile);
      const userDataParsed = _.get(userData, 'data.data.attributes', false);

      const { firstName, lastName } = userDataParsed;
      saveUserName({
        firstName,
        lastName,
      });

      if (statusCode === 200 && idvPass) {
        yield put(saveUserProfile({ userData: userDataParsed }));
        yield put(updateProfileSuccess({
          accessToken,
          refreshToken,
          identityToken,
          expiresIn,
          userDataParsed,
        }));
        if (userDataParsed['custom:verified'] === 'Y') {
          if (callbackUri && accessToken && refreshToken && identityToken && expiresIn) {
            window.location.replace(`${callbackUri}?accessToken=${accessToken}&refreshToken=${refreshToken}&identityToken=${identityToken}&expiresIn=${expiresIn}`);
          }
        } else if (!userDataParsed['custom:phone_verification'] || userDataParsed['custom:phone_verification'] === 'N') {
          push(`/idv/otp${callbackUri ? callbackUriStr : ''}`);
          yield put(updateProfileSuccess({
            accessToken,
            refreshToken,
            identityToken,
            expiresIn,
            userDataParsed,
          }));
        } else if (userDataParsed['custom:gov_id_verification'] === '-') {
          push(`/idv/select-gov-id-type${callbackUri ? callbackUriStr : ''}`);
          yield put(updateProfileSuccess({
            accessToken,
            refreshToken,
            identityToken,
            expiresIn,
            userDataParsed,
          }));
        }
      } else {
        let errorMessage = '';
        if (phoneDuplicate) {
          errorMessage = PHONE_DUPLICATE_ERROR_MESSAGE;
        } else if (hardFail) {
          errorMessage = 'You failed identity verification';
          push(`/idv/failed${callbackUri ? callbackUriStr : ''}`);
        } else if (softFail) {
          yield put(saveUserProfile({ userData: userDataParsed }));
          yield put(updateProfileSuccess({
            accessToken,
            refreshToken,
            identityToken,
            expiresIn,
            userDataParsed,
          }));
          push(`/idv/otp${callbackUri ? callbackUriStr : ''}`);
        }
        yield put(updateProfileFailure(statusCode, errorMessage));
      }
    } catch (e) {
      console.error('error caught in updateProfileSaga', e);
      const errorDetail = _.get(e.response, 'data.errors.0.message', false);
      const statusCode = _.get(e.response, 'data.data.statusCode', false);
      yield put(updateProfileFailure(statusCode, errorDetail));
    }
  }


  function* watchUpdateProfile() {
    yield takeLatest(UPDATE_PROFILE, updateProfileSaga);
  }

  return {
    watchUpdateProfile,
    watchSignOut,
  };
};

export default createUpdateProfileSagas;
