import { createSelector } from 'reselect';
import _ from 'lodash';

const govIdTypeSelectionFormSelector = (state) => state.form;

const govIdTypeSelectionSelector = createSelector(
  govIdTypeSelectionFormSelector,
  (govIdTypeSelectionForm) => {
    return _.get(govIdTypeSelectionForm, 'govIdTypeSelection.values', false);
  }
);

export const getGovIdTypeSelectionFormValues = (state) => govIdTypeSelectionSelector(state);
