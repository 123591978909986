import {
  put,
  takeLatest,
  call,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import _ from 'lodash';
import queryString from 'query-string';
import { Base64 } from 'js-base64';
import {
  REQUEST_OTP,
  REQUEST_OTP_COOLDOWN_EXPIRE,
  VERIFY_OTP,
  VERIFY_SSN,
  FETCH_PROFILE,
  FETCH_GOV_ID_RESPONSE,
  REQUEST_OTP_HIDE_ANIMATION,
} from '../constants';
import {
  verifyOtpSuccess,
  verifyOtpFailure,
  requestOtpSuccess,
  requestOtpFailure,
  verifySsnSuccess,
  verifySsnFailure,
  fetchProfileFailure,
  fetchProfileSuccess,
  saveUserProfile,
  registerUserFailure,
} from '../actions';
import { fetchUser } from './../../services/auth';

const createIdvSagas = (api) => {
  function* fetchGovIdResponse(action) {
    const {
      code,
      push,
    } = action.payload;
    const queryParams = queryString.parse(window.location.search);
    const stateFromIdw = _.get(queryParams, 'state', false);
    let callbackUri;
    let govIdType;
    let accessToken = false;
    let clientId = false;
    if (stateFromIdw) {
      let decodedStateQueryParamObj;
      try {
        const decodedStateQueryParam = Base64.decode(decodeURIComponent(stateFromIdw));
        decodedStateQueryParamObj = JSON.parse(decodedStateQueryParam);
      } catch (error) {
        push(`/idv/failed?callbackUri=${callbackUri}`);
      }
      callbackUri = _.get(decodedStateQueryParamObj, 'callbackUri', false);
      govIdType = _.get(decodedStateQueryParamObj, 'govIdType', false);
      accessToken = _.get(decodedStateQueryParamObj, 'accessToken', false);
      clientId = _.get(decodedStateQueryParamObj, 'clientId', false);
    }

    try {
      const response = yield call(api.fetchGovIdResponse, {
        code,
        govIdType,
        accessToken,
      });
      const statusCode = _.get(response, 'status', false);
      const duplicatedEmail = _.get(response, 'data.data.duplicatedEmail', '');
      if (statusCode === 200) {
        const idvResponse = _.get(response, 'data.data.pass');
        if (idvResponse) {
          const user = fetchUser();
          if (callbackUri) {
            const redirect = `${callbackUri}?accessToken=${accessToken || user.accessToken}&refreshToken=${user.refreshToken}&identityToken=${user.identityToken}&expiresIn=${user.expiresIn}`;
            window.location.replace(redirect);
          }
        } else if (duplicatedEmail) {
          push(`/user-duplicated?clientId=${clientId}`);
          yield put(registerUserFailure(statusCode, '', duplicatedEmail));
        } else {
          push(`/idv/failed?callbackUri=${callbackUri}`);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-empty
    }
  }

  function* watchFetchGovIdResponse() {
    yield takeLatest(FETCH_GOV_ID_RESPONSE, fetchGovIdResponse);
  }

  function* requestOtpSaga(action) {
    const {
      type,
      showOtpRequestAnimation,
    } = action.payload;
    try {
      const response = yield call(api.requestOtp, { type });
      const statusCode = _.get(response, 'status', false);
      if (statusCode === 200) {
        const asi = _.get(response, 'data.data.asi', null);
        yield put(requestOtpSuccess({ asi }));
      } else {
        const errorDetail = _.get(response, 'data.errors.0.message', false);
        yield put(requestOtpFailure({
          statusCode,
          errorDetail,
        }));
      }

      if (showOtpRequestAnimation) {
        yield delay(5000);
        yield put({ type: REQUEST_OTP_HIDE_ANIMATION });
        yield delay(25000);
        yield put({ type: REQUEST_OTP_COOLDOWN_EXPIRE });
      } else {
        yield delay(30000);
        yield put({ type: REQUEST_OTP_COOLDOWN_EXPIRE });
      }
    } catch (e) {
      const errorDetail = _.get(e.response, 'data.data.message', false);
      yield put(requestOtpFailure({ errorDetail }));
    }
  }


  function* watchRequestOtp() {
    yield takeLatest(REQUEST_OTP, requestOtpSaga);
  }

  function* verifyOtpSaga(action) {
    const {
      asi,
      pin,
      type,
      push,
      onVerifyFailed,
    } = action.payload;
    const queryParams = queryString.parse(window.location.search);
    const callbackUri = _.get(queryParams, 'callbackUri', false);
    const clientId = _.get(queryParams, 'clientId', false);
    const callbackUriStr = callbackUri ? `?callbackUri=${callbackUri}&clientId=${clientId}` : '';
    try {
      const response = yield call(api.verifyOtp, {
        asi,
        pin,
        type,
      });
      const statusCode = _.get(response, 'data.data.statusCode', false);
      const hardFail = _.get(response, 'data.data.hardFail', false);
      const user = fetchUser();
      if (statusCode === 200) {
        const userData = yield call(api.fetchProfile);
        const userDataParsed = _.get(userData, 'data.data.attributes', false);

        if (hardFail) {
          push(`/idv/failed${callbackUriStr}`);
          yield put(verifyOtpSuccess({ response }));
        } else if (userDataParsed['custom:gov_id_verification'] === '-' && userDataParsed['custom:verified'] === 'N') {
          push(`/idv/select-gov-id-type${callbackUriStr}`);
          yield put(verifyOtpSuccess({ response }));
        } else if (userDataParsed['custom:gov_id_verification'] === 'X' && userDataParsed['custom:verified'] === 'N' && userDataParsed['custom:ssn_verification'] === 'N' && userDataParsed['custom:phone_verification'] === 'N') {
          if (onVerifyFailed()) {
            push(`/idv/failed${callbackUriStr}`);
          }
        } else if (
          userDataParsed['custom:verified'] === 'Y' ||
          (userDataParsed['custom:verified'] === 'N' && userDataParsed['custom:ssn_verification'] === 'Y' && userDataParsed['custom:phone_verification'] === 'Y') ||
          (userDataParsed['custom:verified'] === 'N' && userDataParsed['custom:ssn_verification'] === 'N' && userDataParsed['custom:gov_id_verification'] === 'Y' && userDataParsed['custom:phone_verification'] === 'Y')
        ) {
          const defaultCallbackUri = '/update-profile';
          window.location.replace(`${callbackUri || defaultCallbackUri}?accessToken=${user.accessToken}&refreshToken=${user.refreshToken}&identityToken=${user.identityToken}&expiresIn=${user.expiresIn}`);
        } else {
          push(`/idv/failed${callbackUriStr}`);
          yield put(verifyOtpSuccess({ response }));
        }
      } else {
        const errorDetail = _.get(response, 'data.errors.0.message', false);
        if (onVerifyFailed()) {
          push(`/idv/ssn4${callbackUri ? callbackUriStr : ''}`);
        }
        yield put(verifyOtpFailure({
          statusCode,
          errorDetail,
        }));
      }
    } catch (e) {
      const errorDetail = _.get(e.response, 'data.data.message', false);
      if (onVerifyFailed()) {
        push(`/idv/ssn4${callbackUriStr}`);
      }
      yield put(verifyOtpFailure({ errorDetail }));
    }
  }


  function* watchVerifyOtp() {
    yield takeLatest(VERIFY_OTP, verifyOtpSaga);
  }

  function* verifySsnSaga(action) {
    const {
      ssn,
      push,
    } = action.payload;

    try {
      const response = yield call(api.verifySsn, { ssn });
      const statusCode = _.get(response, 'data.data.statusCode', false);
      if (statusCode === 200) {
        yield put(verifySsnSuccess());
        const queryParams = queryString.parse(window.location.search);
        const callbackUri = _.get(queryParams, 'callbackUri', false);
        const user = fetchUser();
        const userData = yield call(api.fetchProfile);
        const userDataParsed = _.get(userData, 'data.data.attributes', false);
        if (userDataParsed['custom:ssn_verification'] === 'Y') { // custom:verified
          if (callbackUri) {
            window.location.replace(`${callbackUri}?accessToken=${user.accessToken}&refreshToken=${user.refreshToken}&identityToken=${user.identityToken}&expiresIn=${user.expiresIn}`);
          }
        } else {
          push('/idv/failed');
        }
        const errorDetail = _.get(response, 'data.errors.0.message', false);
        yield put(verifySsnFailure({
          statusCode,
          errorDetail,
        }));
      }
    } catch (e) {
      const errorDetail = _.get(e.response, 'data.data.message', false);
      yield put(verifySsnFailure({ errorDetail }));
    }
  }


  function* watchVerifySsn() {
    yield takeLatest(VERIFY_SSN, verifySsnSaga);
  }

  function* fetchProfileSaga(action) {
    const { isUpdateProfile } = action.payload;
    try {
      const queryParams = queryString.parse(window.location.search);
      let accessToken = _.get(queryParams, 'accessToken', false);
      if (!accessToken) {
        const user = fetchUser();
        ({ accessToken } = user);
      }
      const response = yield call(api.fetchProfile, accessToken);
      const userDataParsed = _.get(response, 'data.data.attributes', false);
      yield put(saveUserProfile({ userData: userDataParsed }));
      const statusCode = _.get(response, 'data.data.statusCode', false);
      if (statusCode === 200) {
        const profile = _.get(response, 'data.data.attributes', null);
        yield put(fetchProfileSuccess(profile, isUpdateProfile));
      } else {
        const errorDetail = _.get(response, 'data.data.message', false);
        yield put(fetchProfileFailure({ errorDetail }));
      }
    } catch (e) {
      const errorDetail = _.get(e.response, 'data.data.message', false);
      yield put(fetchProfileFailure({ errorDetail }));
    }
  }

  function* watchFetchProfile() {
    yield takeLatest(FETCH_PROFILE, fetchProfileSaga);
  }

  return {
    watchRequestOtp,
    watchVerifyOtp,
    watchVerifySsn,
    watchFetchProfile,
    watchFetchGovIdResponse,
  };
};

export default createIdvSagas;
